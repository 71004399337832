// LoadingLottie.tsx
import React from 'react';
import LottieAnimation from './LottieAnimation';

const LoadingLottie: React.FC = () => (
  <div className="min-h-[100vh] w-full flex items-center justify-center">
    <div className="w-24 h-24 md:w-32 md:h-32">
      <LottieAnimation
        path="/loading-lottie-A.json"
        width="100%"
        height="100%"
      />
    </div>
  </div>
);

export default LoadingLottie;
