import Button from './atoms/Button';

interface SideButtonConfig {
  label?: string;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'tertiary';
  icon?: React.ReactNode;
  disabled?: boolean;
}

interface MainButtonConfig extends SideButtonConfig {
  image?: string
}

interface AdminSectionTitleProps {
  title: string;
  subtitle?: string;
  mainButtons?: MainButtonConfig[];
  sideButtons?: SideButtonConfig[];
  className?: string;
}

const AdminSectionTitle = ({
  title, subtitle, sideButtons, mainButtons, className,
}: AdminSectionTitleProps) => (
  <div className={`flex flex-col gap-8 self-stretch ${className}`}>
    <div className="flex flex-row items-center gap-4">
      <h1 className="text-3xl text-gray-700 font-medium">{title}</h1>
      <img src={'/event-svg-2.svg'} alt="" />
      <img src={'/event-svg-1.svg'} alt="" />
    </div>
    <div>
      <h1 className={`flex ${mainButtons && mainButtons.length === 1 ? 'flex-row' : 'flex-col md:flex-row'} gap-2 text-2xl`}>
        <div className="flex flex-row gap-2 items-center">
          {subtitle}
          {sideButtons && (
            <div className="flex gap-2">
              {sideButtons.map((button) => (
                <div key={button.label} className="flex items-center justify-center w-10 whitespace-nowrap">
                  <Button
                    onClick={button.onClick}
                    variant={button.variant}
                    disabled={button.disabled}
                  >
                    {button.icon}
                    {button.label}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        {mainButtons && (
          <div className={'flex flex-row gap-2 items-center md:ml-auto'}>
            {mainButtons.map((button, index) => (
              <Button key={index} onClick={button.onClick} variant={button.variant} disabled={button.disabled}>
                <div className="flex items-center justify-center gap-2 p-2">
                  <img src={button.image} alt={button.label} className={`${button.disabled ? 'brightness-50' : 'brightness-0'} min-w-[20px] min-h-[20px] w-5 h-5`} />
                  <p className="hidden min-[1150px]:block whitespace-nowrap">{button.label}</p>
                </div>
              </Button>
            ))}
          </div>
        )}
      </h1>
    </div>
  </div>
);

export default AdminSectionTitle;
