import { useSelector } from 'react-redux';
import Button from '../components/atoms/Button';
import { RootState } from '../store';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import Carrousel from '../components/atoms/Carrousel/Carrousel';
import Image from '../components/atoms/Image';

const CreateOrderError: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  if (!event) {
    console.error('no selected event');
  }
  const handleClickTryAgain = () => {
    navigate('../');
  };
  return (
    <div className="flex flex-col gap-6 my-6">
      <div className="flex items-center justify-center w-full">
        <Carrousel
          images={[{ url: event?.banner_image || '', eventId: event?.id || 0 }]}
        />
      </div>
      <div className="flex flex-col md:flex-row gap-6 bg-white rounded-lg shadow-sm p-4">
        <div className="flex items-center justify-center p-4">
          <Image
            src="/x-error.svg"
            alt="error"
            className="w-auto h-auto max-w-[160px] object-contain"
          />
        </div>
        <div className="flex flex-col gap-4 p-2 justify-center flex-start text-center md:text-left">
          <h1>Tu solicitud no pudo ser generada de forma correcta</h1>
          <div>
            <p>
              Debido a algún inconveniente no pudimos procesar tu solicitud de
              entradas de forma correcta, por lo que te pedimos que intentes
              nuevamente en realizar dicho pedido.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Button variant="primary" onClick={handleClickTryAgain}>
          Reintentar
        </Button>
        <div className="px-3 text-sm text-center">
          <p>
            Si el problema persiste te pedimos que te comuniques a nuestro{' '}
            <a className="underline" href="https://wa.me/542645515045" target="_blank">
              WhatsApp
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
/* <div className="flex justify-center mt-10">
        <Button secondary className="mt-10" onClick={handleClickTryAgain}>
          Reintentar
        </Button>
      </div>
      <div className="flex justify-center mt-3">
        <Button secondary className="mt-10" onClick={handleClickContact}>
          Hablar con operadora
        </Button>
      </div> */

export default CreateOrderError;
