import { GoSync } from 'react-icons/go';
import React, { FC, ReactNode } from 'react';

const btnPrimaryStyles = `
text-md-semibold text-white
bg-grey-700
border-solid border border-grey-700
shadow-xs
rounded-lg
hover:bg-grey-600 hover:border-grey-600
disabled:bg-grey-400 disabled:border-grey-400
`;
const btnSecondaryStyles = `
text-md-semibold text-grey-700
bg-white
border-solid border border-grey-700
shadow-xs
rounded-lg
hover:border-grey-600 hover:text-grey-600
disabled:border-grey-400 disabled:text-grey-400
`;
const btnTertiaryStyles = `
text-md-semibold text-primary-base
bg-transparent
border-none rounded-lg
shadow-none
hover:text-primary-light hover:border-none hover:bg-transparent hover:shadow-none
focus:text-primary-base focus:border-none focus:bg-background-blue focus:shadow-none
disabled:text-grey-400 disabled:border-none disabled:bg-transparent disabled:shadow-none
`;
const btnDangerStyles = `
text-md-semibold text-white
bg-error-dark
border-solid border border-error-dark
shadow-xs
rounded-lg
hover:bg-error-base hover:border-error-base
disabled:bg-grey-400 disabled:border-grey-400
`;
const btnPageStyles = `
text-sm-regular text-grey-600
border-none rounded-lg
shadow-none
hover:text-grey-600 hover:border-none hover:shadow-none
focus:text-grey-600 focus:border-none focus:shadow-none
disabled:text-grey-400 disabled:border-none disabled:bg-transparent disabled:shadow-none
`;
const md = 'py-2.5 px-4 h-11';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  size?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'page';
  loading?: boolean;
  isSelect?: boolean
}
const getStyleBtn = (variant: string) => {
  switch (variant) {
    case 'primary':
      return btnPrimaryStyles;
    case 'secondary':
      return btnSecondaryStyles;
    case 'tertiary':
      return btnTertiaryStyles;
    case 'danger':
      return btnDangerStyles;
    case 'page':
      return btnPageStyles;
    default:
      return btnPrimaryStyles;
  }
};

const getPaddingBtn = (size: string | undefined) => {
  switch (size) {
    case 'md':
      return md;
    default:
      return md;
  }
};
const Button: FC<ButtonProps> = ({
  children,
  disabled = false, size = 'md', type = 'button', variant = 'primary',
  loading,
  isSelect,
  ...rest
}) => {
  const btnStyle = getStyleBtn(variant);
  const btnPadding = getPaddingBtn(size);

  return (
    <button {...rest} disabled={loading || disabled} type={type} className={
      `cursor-pointer normal-case w-full flex items-center justify-center
      ${btnPadding}
      ${btnStyle}
      ${btnStyle === btnPageStyles && isSelect ? 'bg-[#f9d8ed]' : ''}
      `}>
      {loading
        && <GoSync className='animate-spin' />
      }
      <span className={`${loading ? 'ml-2' : ''}`}>{children}</span>
    </button>
  );
};

export default Button;
