import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import Input from './atoms/Input';
import { displayUserPermissions, UserPermissions, userPermissionsArray } from '../models/user';
import Select from './atoms/Select/Select';
import Button from './atoms/Button';
import { useCreateInvitationMutation } from '../store';
import { toastSucces } from '../utils/toasts';
import Chip from './atoms/Chip';
import { CreateInvitationDTO, EventUserPermissions } from '../models/invitation';
import useGetSelectedBusiness from '../hooks/useGetSelectedBusiness';
import LoadingLottie from './atoms/LoadingLottie';

interface Props {
  businessId: number;
  onSuccess: () => void;
}

interface FormData {
  email: string;
  permissions: UserPermissions;
  seller_commission_rate?: number;
  selected_events?: number[];
}

const noSpaces = (value: string) => !/\s/.test(value);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object<FormData>().shape({
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  permissions: yup
    .mixed<UserPermissions>()
    .required('Debes seleccionar una opción')
    .oneOf(userPermissionsArray, 'La opción seleccionada no es válida'),
  seller_commission_rate: yup
    .number()
    .when('permissions', {
      is: 'SELLER',
      then: (schema) => schema
        .required('Debes ingresar una comisión')
        .min(0, 'La comisión no puede ser negativa')
        .max(100, 'La comisión no puede ser mayor a 100'),
    }),
  selected_events: yup
    .array()
    .of(yup.number().required())
    .when('permissions', {
      is: 'SELLER',
      then: (schema) => schema
        .required('Debes seleccionar al menos un evento'),
    }),
});

const InviteUser: React.FC<Props> = ({ businessId, onSuccess }) => {
  const form = useForm<FormData>({
    resolver: yupResolver<FormData>(validationSchema),
    defaultValues: {
      email: '',
      permissions: undefined,
      selected_events: [],
      seller_commission_rate: undefined,
    },
  });

  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const { setValue } = form;

  const { business, businessLoading } = useGetSelectedBusiness();

  const {
    control, handleSubmit, formState, watch,
  } = form;
  const permissions = watch('permissions');

  const [createInvitation, createInvitationResults] = useCreateInvitationMutation();

  if (!business && !businessLoading) {
    throw new Error('no business selected');
  }

  if (businessLoading) {
    return <LoadingLottie />;
  }

  const formToDto = (formData: FormData): CreateInvitationDTO => {
    console.log(selectedEvents);
    return ({
      email: formData.email,
      permissions: formData.permissions,
      invitation_events: selectedEvents ? selectedEvents.map((e) => ({
        event_id: e,
        permissions: EventUserPermissions.SELLER,
        seller_comission_rate: formData.seller_commission_rate ?? 0,
      })) : undefined,
    });
  };

  const handleSendInvite = async () => {
    try {
      const formData = form.getValues();
      const result = await createInvitation({
        businessId,
        dto: formToDto(formData),
      });
      if ('data' in result) {
        toastSucces('Invitación enviada con éxito');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleSendInvite)}>
        <div className="mb-5">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                label="Correo electrónico"
                labelAbove={true}
                id="email"
                {...field}
                onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))}
                errMsg={formState.errors.email?.message}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="permissions"
            control={control}
            defaultValue={undefined}
            render={({ field }) => (
              <Select
                label="Nivel de permisos"
                id="permissions"
                options={userPermissionsArray.filter((p) => p !== 'USER').map((p) => ({
                  label: displayUserPermissions[p as keyof typeof displayUserPermissions],
                  value: p,
                }))}
                {...field}
              />
            )}
          />
        </div>

        {permissions === 'SELLER' && (
          <>
            <div className="mb-5">
              <Controller
                name="seller_commission_rate"
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <Input
                    label="Comisión del vendedor (%)"
                    labelAbove={true}
                    id="seller_commission_rate"
                    type="number"
                    {...field}
                    value={field.value ?? ''}
                    onChange={(e) => field.onChange(parseFloat(e.target.value) || undefined)}
                    errMsg={formState.errors.seller_commission_rate?.message}
                  />
                )}
              />
            </div>
            <div className="mb-5">
              <Controller
                name="selected_events"
                control={control}
                render={() => (
                  <div>
                    <label className="block mb-2">Eventos asignados</label>
                    <Select
                      label="Seleccionar evento"
                      id="event-select"
                      value=""
                      onChange={(value) => {
                        const eventId = +value.target.value;
                        if (!selectedEvents.includes(eventId)) {
                          const newSelectedEvents = [...selectedEvents, eventId];
                          setSelectedEvents(newSelectedEvents);
                        }
                      }}
                      options={business?.events
                        .filter((event) => !selectedEvents.includes(event.id))
                        .map((event) => ({
                          label: event.name,
                          value: `${event.id}`,
                        })) ?? []}
                    />
                    <div className="flex flex-wrap gap-2 mt-2">
                      {business?.events
                        .filter((event) => selectedEvents.includes(event.id))
                        .map((event) => (
                          <Chip
                            key={event.id}
                            label={event.name}
                            onDelete={() => {
                              const newSelectedEvents = selectedEvents.filter((id) => id !== event.id);
                              setSelectedEvents(newSelectedEvents);
                              setValue('selected_events', newSelectedEvents);
                            }}
                          />
                        ))}
                    </div>
                    {formState.errors.selected_events && (
                      <span className="text-red-500 text-sm">
                        {formState.errors.selected_events.message}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
          </>
        )}

        <div className="flex justify-center align-items-center">
          <Button
            className="w-full"
            variant="primary"
            type='submit'
            loading={createInvitationResults.isLoading}
          >
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteUser;
