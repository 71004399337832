import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../pages/ErrorPage';
import sendError from '../utils/services';
import config from '../config/config';

interface Props {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const logError = (error: Error, info: { componentStack: string }) => {
    console.error('Uncaught error:', error, info);
    if (config.production) {
      sendError({
        error: error.message,
        info: info.componentStack,
      });
    }
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorPage}
      onError={logError}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
