import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { GoSync } from 'react-icons/go';

interface Option<T> {
  hidden?: boolean;
  label: string;
  onClick: (data: T) => void;
  labelClassName?: string;
}

interface DropdownProps<T> {
  data: T;
  options: Option<T>[];
  loading?: boolean;
  fixed?: boolean;
  label?: string;
  icon?: React.ReactNode;
  className?: string;
}
function MenuDropdown<T>({
  data, options, loading, label, icon,
}: DropdownProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="relative inline-block">
      {loading ? (
        <GoSync className="animate-spin" />
      ) : (
        <button
          className={`cursor-pointer flex items-center gap-1 ${label ? 'px-2 py-1' : 'text-2xl'}`}
          onClick={handleClick}
        >
          <div className="flex items-center">
            {label && <span>{label}</span>}
            {icon || (!label && '⋮')}
          </div>
        </button>
      )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {options.map((option) => (
            !option.hidden && (
              <MenuItem key={option.label} sx={{
                '&.MuiMenuItem-root': {
                  fontFamily: 'Rubik',
                  color: '#111827',
                },
              }} onClick={() => {
                option.onClick(data);
                setAnchorEl(null);
              }}><div className={`cursor-pointer ${option.labelClassName}`}>{option.label}</div></MenuItem>
            )
          ))}
        </Menu>
    </div>
  );
}

export default MenuDropdown;
