import React from 'react';
import ReactMarkdown from 'react-markdown';
import CustomModal from './atoms/CustomModal';
import Button from './atoms/Button';
import TermsAndServicesType from '../models/terms';

const termsFiles = {
  [TermsAndServicesType.BUYER]: '/buyer-terms.md',
  [TermsAndServicesType.SELLER]: '/seller-terms.md',
};

interface TermsAndServicesProps {
  isOpen: boolean;
  onClose: () => void;
  type: TermsAndServicesType;
}

const TermsAndConditions: React.FC<TermsAndServicesProps> = ({
  isOpen,
  onClose,
  type,
}) => {
  const [terms, setTerms] = React.useState<string>('');

  React.useEffect(() => {
    fetch(termsFiles[type])
      .then((response) => response.text())
      .then((text) => setTerms(text))
      .catch((error) => console.error('Error loading terms:', error));
  }, []);

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose} hideCancelButton>
      <div className="flex flex-col gap-3 md:gap-4 max-w-[90vw] max-h-[90vh]">
        <h1 className="text-xl md:text-2xl font-bold px-4 border-b border-gray-300 pb-1">Términos y Condiciones</h1>
        <ReactMarkdown className="overflow-y-auto px-4 max-h-[40vh] md:max-h-[50vh]">{terms}</ReactMarkdown>
        <div className="px-4 border-t border-gray-300 pt-2">
          <Button variant="primary" onClick={onClose} className="w-full">
            Acepto
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default TermsAndConditions;
