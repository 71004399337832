import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Switch from './atoms/Switch';
import Input from './atoms/Input';
import Button from './atoms/Button';
import { useCreateEventMutation, useUpdateEventMutation } from '../store';
import FormControlTextArea from './atoms/FormControlTextArea';
import { setSelectedEvent } from '../store/slices/events';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetSelectedBusiness from '../hooks/useGetSelectedBusiness';
import LoadingLottie from './atoms/LoadingLottie';
import useGetUserRole from '../hooks/useGetUserRole';
import formatDate from '../utils/formatDate';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import { combineDateAndTime } from '../utils/dates';
import PhotoUpload from './PhotoUpload';
import ErrorMessage from './atoms/ErrorMessage';

interface Props {
  create: boolean;
}

interface FormData {
  name: string;
  description: string;
  date: string;
  time: string;
  place: string;
  terms: string | undefined;
  phone: string;
  min_age: number;
  address: string;
  require_id_tickets: boolean;
  is_public: boolean;
  active: boolean;
  banner_image: string;
  seller_sales_enabled: boolean;
  // ROOT only fields
  maps_link?: string | undefined;
  allow_online_payments?: boolean;
  allow_manual_payments?: boolean;
  online_service_charge?: number;
  manual_service_charge?: number;
  receives_wire_transfers?: boolean;
  has_polls?: boolean;
  has_numerated_seats?: boolean;
  tickets_includes_service_charge?: boolean;
  has_seller_qr?: boolean;
  has_scanner_auto_accept?: boolean;
  has_order_qrs?: boolean;
  is_important?: boolean;
  is_approved?: boolean;
}

const EventEditor: React.FC<Props> = ({ create }) => {
  const navigate = useNavigateWithQuery();
  const { event_id: eventId, business_id: businessId } = useParams<{
    event_id: string;
    business_id: string;
  }>();
  const { business, businessLoading } = useGetSelectedBusiness();
  const userRole = useGetUserRole();

  const { event, eventLoading } = useGetSelectedEvent({ skip: !eventId });

  const getValidationSchema = () => {
    const baseSchema = {
      name: yup.string().required('Debes ingresar un nombre'),
      description: yup.string().required('Debes ingresar una descripción'),
      date: yup.string().required('Debes ingresar una fecha'),
      time: yup.string().required('Debes ingresar una hora'),
      place: yup.string().required('Debes ingresar un lugar'),
      require_id_tickets: yup.boolean().required(),
      is_public: yup.boolean().required(),
      active: yup.boolean().required(),
      terms: yup.string().optional(),
      phone: yup.string().required('Debes ingresar un teléfono'),
      min_age: yup
        .number()
        .required('Debes ingresar una edad mínima')
        .typeError('Debe ser un número'),
      address: yup.string().required('Debes ingresar una dirección'),
      banner_image: yup
        .string()
        .url('La imagen no tiene una URL válida')
        .required('Debes subir una imagen'),
      seller_sales_enabled: yup.boolean().required(),
    };

    if (userRole === 'ROOT') {
      return yup.object().shape({
        ...baseSchema,
        maps_link: yup.string().url('Debe ser una URL válida').optional(),
        allow_online_payments: yup.boolean().required(),
        allow_manual_payments: yup.boolean().required(),
        online_service_charge: yup
          .number()
          .required('Debes ingresar un cargo por servicio')
          .min(0, 'El cargo por servicio debe ser al menos 0')
          .max(1, 'El cargo por servicio no puede ser mayor que 1')
          .typeError('Debe ser un número'),
        manual_service_charge: yup
          .number()
          .required('Debes ingresar un cargo por servicio')
          .min(0, 'El cargo por servicio debe ser al menos 0')
          .max(1, 'El cargo por servicio no puede ser mayor que 1')
          .typeError('Debe ser un número'),
        receives_wire_transfers: yup.boolean().required(),
        has_polls: yup.boolean().required(),
        has_numerated_seats: yup.boolean().required(),
        tickets_includes_service_charge: yup.boolean().required(),
        has_seller_qr: yup.boolean().required(),
        has_scanner_auto_accept: yup.boolean().required(),
        has_order_qrs: yup.boolean().required(),
        is_important: yup.boolean().required(),
        is_approved: yup.boolean().required(),
      });
    }

    return yup.object().shape(baseSchema);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
      time: '',
      place: '',
      terms: '',
      phone: '',
      min_age: 0,
      address: '',
      require_id_tickets: false,
      is_public: false,
      active: true,
      banner_image: '',
      seller_sales_enabled: false,
      ...(userRole === 'ROOT' && {
        maps_link: '',
        allow_online_payments: true,
        allow_manual_payments: true,
        online_service_charge: business?.online_service_charge ?? 0.15,
        manual_service_charge: business?.manual_service_charge ?? 0.05,
        receives_wire_transfers: false,
        has_polls: false,
        has_numerated_seats: false,
        tickets_includes_service_charge: false,
        has_seller_qr: false,
        has_scanner_auto_accept: false,
        has_order_qrs: false,
        is_important: false,
        is_approved: business?.is_reliable ?? false,
      }),
    },
    resolver: yupResolver(getValidationSchema()),
    mode: 'onChange',
  });

  useEffect(() => {
    if (event) {
      reset({
        name: event.name || '',
        description: event.description || '',
        date: formatDate(event.date) || new Date().toISOString().split('T')[0],
        time: event.time || '',
        place: event.place || '',
        require_id_tickets: event.require_id_tickets || false,
        terms: event.terms || '',
        phone: event.phone || '',
        min_age: event.min_age || 0,
        address: event.address || '',
        is_public: event.is_public || false,
        active: event.active,
        banner_image: event.banner_image || '',
        seller_sales_enabled: event.seller_sales_enabled ?? false,
        ...(userRole === 'ROOT' && {
          maps_link: event.maps_link,
          allow_online_payments: event.allow_online_payments,
          allow_manual_payments: event.allow_manual_payments,
          online_service_charge: event.online_service_charge ?? business?.online_service_charge,
          manual_service_charge: event.manual_service_charge ?? business?.manual_service_charge,
          receives_wire_transfers: event.receives_wire_transfers,
          has_polls: event.has_polls,
          has_numerated_seats: event.has_numerated_seats ?? false,
          tickets_includes_service_charge: event.tickets_includes_service_charge ?? false,
          has_seller_qr: event.has_seller_qr ?? false,
          has_scanner_auto_accept: event.has_scanner_auto_accept ?? false,
          has_order_qrs: event.has_order_qrs ?? false,
          is_important: event.is_important ?? false,
          is_approved: event.is_approved ?? false,
        }),
      });
    }
  }, [event, reset, userRole]);

  const [createEvent, resCreateEvent] = useCreateEventMutation();
  const [updateEvent, resUpdateEvent] = useUpdateEventMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resCreateEvent.isSuccess || resUpdateEvent.isSuccess) {
      toast.success(`Evento ${create ? 'creado' : 'actualizado'} con éxito`, {
        duration: 5000,
        position: 'bottom-center',
      });
      if (resUpdateEvent.data?.data) {
        dispatch(setSelectedEvent(resUpdateEvent.data.data));
      }
      navigate('..');
    }
  }, [resCreateEvent.isSuccess, resUpdateEvent.isSuccess]);

  const formDataToDto = (data: FormData) => {
    const eventDateTime = combineDateAndTime(data.date, data.time);
    const baseEventData = {
      name: data.name,
      description: data.description,
      date: eventDateTime,
      time: data.time,
      place: data.place,
      terms: data.terms,
      phone: data.phone,
      min_age: data.min_age,
      address: data.address,
      require_id_tickets: data.require_id_tickets,
      banner_image: data.banner_image,
      is_public: data.is_public,
      active: data.active,
      seller_sales_enabled: data.seller_sales_enabled,
    };
    if (userRole !== 'ROOT') {
      return baseEventData;
    }
    return {
      ...baseEventData,
      maps_link: data.maps_link,
      allow_online_payments: data.allow_online_payments,
      allow_manual_payments: data.allow_manual_payments,
      online_service_charge: data.online_service_charge,
      manual_service_charge: data.manual_service_charge,
      receives_wire_transfers: data.receives_wire_transfers,
      has_polls: data.has_polls,
      has_numerated_seats: data.has_numerated_seats,
      tickets_includes_service_charge:
      data.tickets_includes_service_charge,
      has_seller_qr: data.has_seller_qr,
      has_scanner_auto_accept: data.has_scanner_auto_accept,
      has_order_qrs: data.has_order_qrs,
      is_important: data.is_important,
      is_approved: data.is_approved,
    };
  };

  const onSubmit = async (data: FormData) => {
    if (!businessId) {
      throw new Error('business not found');
    }
    try {
      const eventData = formDataToDto(data);

      if (event) {
        await updateEvent({
          event: { ...eventData, id: eventId ? +eventId : 0 },
          businessId: +(businessId || 0),
        });
      } else {
        await createEvent({
          event: eventData,
          businessId: +(businessId || 0),
        });
      }
    } catch (e) {
      console.error(e);
      toast.error('Ocurrió un error', {
        duration: 5000,
        position: 'bottom-center',
      });
    }
  };

  if (eventLoading || businessLoading) {
    return <LoadingLottie />;
  }

  return (
    <div className="px-5 my-5">
      <div className="text-gray-900 text-5xl">
        {create ? 'Crear nuevo evento' : 'Editar evento'}
      </div>
      <div className=" my-5 text-xl font-light">
        Productora: {business?.name}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
      {userRole === 'ROOT' && <div className="mb-2">
          <div className="text-gray-900 text-lg font-medium">Evento importante</div>
          <div className="text-gray-500 text-xs font-light mb-2">
            Los eventos importantes se muestran en la portada
          </div>
          <Switch
            label="Evento importante"
            labelClassName="text-gray-900 text-lg font-light"
            checked={watch('is_important')}
            onChange={(e) => setValue('is_important', e.target.checked)}
          />
        </div>}
        <div className="mb-2">
          <div className="text-gray-900 text-lg font-medium">Visibilidad</div>
          <div className="text-gray-500 text-xs font-light mb-2">
            Los eventos necesitan ser aprobados por el equipo de Avantti para poder vender. Esto no demora más de unas horas.
          </div>
          <Switch
            label="Evento aprobado"
            labelClassName="text-gray-900 text-lg font-light"
            checked={watch('is_approved')}
            disabled={!business?.is_reliable && userRole !== 'ROOT'}
            onChange={(e) => setValue('is_approved', e.target.checked)}
          />
        </div>
        <div className="mb-2">
          <div className="text-gray-900 text-lg font-medium">Estado</div>
          <div className="text-gray-500 text-xs font-light mb-2">
            Los eventos activos están publicados y disponibles para la compra de
            entradas
          </div>
          <Switch
            label="Activo"
            labelClassName="text-gray-900 text-lg font-light"
            checked={watch('active')}
            onChange={(e) => setValue('active', e.target.checked)}
          />
        </div>
        <div className="mb-2">
          <div className="text-gray-900 text-lg font-medium">Visibilidad</div>
          <div className="text-gray-500 text-xs font-light mb-2">
            Los eventos públicos se muestran a todos los usuarios que navegan en
            el sitio de Avantti
          </div>
          <Switch
            label="Público"
            labelClassName="text-gray-900 text-lg font-light"
            disabled={!business?.is_reliable && userRole !== 'ROOT'}
            checked={watch('is_public')}
            onChange={(e) => setValue('is_public', e.target.checked)}
          />
        </div>
        <div className="mb-2">
          <div className="text-gray-900 text-lg font-medium">
            Identificación de entradas
          </div>
          <div className="text-gray-500 text-xs font-light mb-2">
            Los eventos configurados bajo esta condición requieren la
            identificación de entradas al momento que el usuario asiste
          </div>
          <div className="flex flex-col gap-2">
            <Switch
              label="Obligatorio"
              labelClassName="text-gray-900 text-lg font-light"
              checked={watch('require_id_tickets')}
              onChange={(e) => setValue('require_id_tickets', e.target.checked)}
            />
            {userRole === 'ROOT' && (
              <Switch
                label="Entradas numeradas"
                labelClassName="text-gray-900 text-lg font-light"
                checked={watch('has_numerated_seats')}
                onChange={(e) => setValue('has_numerated_seats', e.target.checked)
                }
              />
            )}
          </div>
        </div>
        <div className="mb-2">
          <div className="text-gray-900 text-sm font-medium">Edad mínima</div>
          <Input
            {...register('min_age')}
            id="min_age"
            type="number"
            label="Edad mínima"
            additionalClasses="bg-white"
            errMsg={errors.min_age?.message}
          />
        </div>
        <div className="mb-2">
          <div className="font-medium text-gray-900 text-lg mb-2">
            Datos del evento
          </div>
          <div className="text-gray-900 text-sm font-medium">
            Nombre del evento
          </div>
          <Input
            {...register('name')}
            id="name"
            label=""
            additionalClasses="bg-white mb-4"
            errMsg={errors.name?.message}
          />
          <div className="text-gray-900 text-sm font-medium">Descripción</div>
          <FormControlTextArea
            {...register('description')}
            id="description"
            label="Máximo 2200 caracteres"
            rows={5}
            errMsg={errors.description?.message}
          />
          <div className="text-gray-900 text-sm font-medium">
            Más información
          </div>
          <FormControlTextArea
            {...register('terms')}
            id="terms"
            label=""
            rows={5}
            errMsg={errors.terms?.message}
          />
          <div className="flex flex-row w-full gap-4 mt-4">
            <div className="w-1/2">
              <div className="text-gray-900 text-sm font-medium">Fecha</div>
              <Input
                {...register('date')}
                id="date"
                type="date"
                label="Fecha"
                additionalClasses="bg-white"
                errMsg={errors.date?.message}
              />
            </div>
            <div className="w-1/2">
              <div className="text-gray-900 text-sm font-medium">Hora</div>
              <Input
                {...register('time')}
                id="time"
                type="time"
                label="Hora"
                additionalClasses="bg-white"
                errMsg={errors.time?.message}
              />
            </div>
          </div>
          <div className="text-gray-900 text-sm font-medium mt-4">Lugar</div>
          <Input
            {...register('place')}
            id="place"
            label=""
            additionalClasses="bg-white"
            errMsg={errors.place?.message}
          />
          <div className="text-gray-900 text-sm font-medium mt-4">
            Dirección del evento
          </div>
          <Input
            {...register('address')}
            id="address"
            label=""
            additionalClasses="bg-white"
            errMsg={errors.address?.message}
          />
          {userRole === 'ROOT' && (
            <div>
              <div className="text-gray-900 text-sm font-medium mt-4">
                Enlace de Google Maps
              </div>
              <FormControlTextArea
                {...register('maps_link')}
                id="maps_link"
                label=""
                errMsg={errors.maps_link?.message}
              />
            </div>
          )}
          <div className="text-gray-900 text-sm font-medium mt-4">
            Teléfono de contacto
          </div>
          <Input
            {...register('phone')}
            id="phone"
            label=""
            additionalClasses="bg-white"
            errMsg={errors.phone?.message}
          />
          <div className="mt-4">
            <div className="text-gray-900 text-sm font-medium mt-4">
              Imagen de portada
            </div>
            <PhotoUpload
              id="banner_image"
              onFileUploaded={(url) => setValue('banner_image', url)}
              defaultPreview={event?.banner_image}
            />
            <ErrorMessage message={errors.banner_image?.message} />
          </div>
        </div>

        {userRole === 'ROOT' && (
          <div>
            <div className="mb-2">
              <div className="font-medium text-gray-900 text-lg">
                Métodos de pago
              </div>
              <div className="text-gray-500 text-xs font-light mb-2">
                Configurá las condiciones de pago bajo las que se venderán las
                entradas de tu evento
              </div>
              <div>
                <div className="mb-2">
                  <Switch
                    label="Recibe dinero de transferencias directas al productor"
                    labelClassName="text-gray-900 text-lg font-light"
                    checked={watch('receives_wire_transfers')}
                    onChange={(e) => setValue('receives_wire_transfers', e.target.checked)
                    }
                  />
                </div>
                <div className="mb-2">
                  <Switch
                    label="Acepta pagos de plataforma de pagos"
                    labelClassName="text-gray-900 text-lg font-light"
                    checked={watch('allow_online_payments')}
                    onChange={(e) => setValue('allow_online_payments', e.target.checked)
                    }
                  />
                </div>
                <div className="mb-2">
                  <Switch
                    label="Acepta pagos con transferencias"
                    labelClassName="text-gray-900 text-lg font-light"
                    checked={watch('allow_manual_payments')}
                    onChange={(e) => setValue('allow_manual_payments', e.target.checked)
                    }
                  />
                </div>
                <div className="mb-2">
                  <Switch
                    label="Entradas incluyen service charge"
                    labelClassName="text-gray-900 text-lg font-light"
                    checked={watch('tickets_includes_service_charge')}
                    onChange={(e) => setValue(
                      'tickets_includes_service_charge',
                      e.target.checked,
                    )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium text-gray-900 text-lg">
                Service charge
              </div>
              <div className="text-gray-500 text-xs font-light mb-2">
                Configurá los porcentajes de costos del servicio que se suman al
                monto por entrada
              </div>
              <div className="flex flex-row w-full gap-4 mt-4">
                <div className="w-1/2">
                  <div className="text-gray-900 text-sm font-medium">
                    Service charge online
                  </div>
                  <Input
                    {...register('online_service_charge')}
                    id="online_service_charge"
                    type="number"
                    step="0.01"
                    label=""
                    additionalClasses="bg-white"
                    errMsg={errors.online_service_charge?.message}
                  />
                </div>
                <div className="w-1/2">
                  <div className="text-gray-900 text-sm font-medium">
                    Service charge manual
                  </div>
                  <Input
                    {...register('manual_service_charge')}
                    id="manual_service_charge"
                    type="number"
                    step="0.01"
                    label=""
                    additionalClasses="bg-white"
                    errMsg={errors.manual_service_charge?.message}
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium text-gray-900 text-lg">
                Recolección de datos
              </div>
              <div className="text-gray-500 text-xs font-light mb-2">
                Esta opción habilita sumar una encuesta que completará el
                usuario en el flujo de compra
              </div>
              <div className="mb-2">
                <Switch
                  label="Habilitado QR de públicas"
                  labelClassName="text-gray-900 text-lg font-light"
                  checked={watch('has_seller_qr')}
                  onChange={(e) => setValue('has_seller_qr', e.target.checked)}
                />
              </div>
              <div className="mb-2">
                <Switch
                  label="Tiene encuesta"
                  labelClassName="text-gray-900 text-lg font-light"
                  checked={watch('has_polls')}
                  onChange={(e) => setValue('has_polls', e.target.checked)}
                />
              </div>
            </div>

            <div className="mb-2">
              <div className="font-medium text-gray-900 text-lg">
                Configuración de escáner
              </div>
              <div className="text-gray-500 text-xs font-light">
                Configura el comportamiento del escáner de entradas
              </div>
              <div className="my-2">
                <Switch
                  label="Auto aceptar QRs al escanear"
                  labelClassName="text-gray-900 text-lg font-light"
                  checked={watch('has_scanner_auto_accept')}
                  onChange={(e) => setValue('has_scanner_auto_accept', e.target.checked)
                  }
                />
              </div>
              <div className="mb-2">
                <Switch
                  label="QR único por compra"
                  labelClassName="text-gray-900 text-lg font-light"
                  checked={watch('has_order_qrs')}
                  onChange={(e) => setValue('has_order_qrs', e.target.checked)}
                />
              </div>
            </div>
          </div>
        )}
        <div className="mb-2">
          <div className="font-medium text-gray-900 text-lg">
            Ventas por vendedores
          </div>
          <div className="text-gray-500 text-xs font-light mb-2">
            Habilita o deshabilita las ventas a través de vendedores para este evento
          </div>
          <Switch
            label="Habilitar ventas por vendedores"
            labelClassName="text-gray-900 text-lg font-light"
            checked={watch('seller_sales_enabled')}
            onChange={(e) => setValue('seller_sales_enabled', e.target.checked)}
          />
        </div>
        <div className="flex flex-row gap-4 justify-between mt-4">
          <Button
            type="submit"
            variant="primary"
            className="w-1/2"
            loading={resCreateEvent.isLoading || resUpdateEvent.isLoading}
          >
            Guardar cambios
          </Button>
          <Button
            type="button"
            variant="secondary"
            className="w-1/2"
            onClick={() => navigate('..')}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EventEditor;
