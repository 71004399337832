import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import RequireAuth from '../store/utils/RequireAuth';
import config from '../config/config';
import TermsAndConditions from '../components/TermsAndConditions';
import TermsAndServicesType from '../models/terms';

const AdminOutlet = () => {
  const [showTerms, setShowTerms] = useState(false);

  return (
    <RequireAuth>
      <div>
        <div className="px-3 min-h-screen flex flex-col">
          <div className="flex-grow">
            <Outlet />
          </div>
          <div className="mt-auto pt-4 pb-2 flex flex-col justify-between items-center">
            <div className="w-full text-center text-sm text-gray-600">
              Al vender con Avantti, aceptas nuestros{' '}
              <button
                type="button"
                className="text-primary underline"
                onClick={() => setShowTerms(true)}
              >
                términos y condiciones
              </button>
            </div>
            <div className="flex flex-col items-center">
              <span className="text-xs">v{config.version}</span>
            </div>
          </div>
        </div>

        <TermsAndConditions
          isOpen={showTerms}
          onClose={() => setShowTerms(false)}
          type={TermsAndServicesType.SELLER}
        />
      </div>
    </RequireAuth>
  );
};

export default AdminOutlet;
