import { useState } from 'react';
import toast from 'react-hot-toast';
import MenuDropdown from './atoms/MenuDropdown';
import CustomModal from './atoms/CustomModal';
import { useDeleteCouponMutation } from '../store';
import Button from './atoms/Button';
import CouponDTO from '../types/Coupon';
import CreateEditCoupon from './CreateEditCoupon';
import config from '../config/config';
import FilterTable, { BaseData } from './Table/Table';

interface Props {
  coupons: CouponDTO[];
  eventId: number;
  businessId: number;
}

interface TableCoupons extends BaseData {
  id: number;
  code: string;
  amount: string;
  value: string;
}

const CouponsTable: React.FC<Props> = ({ coupons, eventId, businessId }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [deleteCoupon, resultsDelete] = useDeleteCouponMutation();

  const handleConfirmDeleteCoupon = (coupon: CouponDTO) => {
    deleteCoupon({
      businessId,
      eventId: +eventId,
      couponId: coupon.id,
    });
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteCoupon = (coupon: CouponDTO) => {
    setModalBody(
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-xl font-medium'>Eliminar cupón</h1>
          <p>
            ¿Deseas eliminar este cupón?
          </p>
          <p className='mt-2'>Una vez eliminado desaparecerá de la lista y no se podrá revertir.</p>
        </div>

        <div className='flex flex-row gap-2'>
          <Button

            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => handleConfirmDeleteCoupon(coupon)}
          >
            Eliminar
          </Button>
        </div>
      </div>,
    );
    setShowModal(true);
  };

  const handleEditCoupon = (coupon: CouponDTO) => {
    setModalBody(
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-medium">Editar cupón</h1>
          <p className="text-gray-400 text-sm">
            Podrás realizar las ediciones que necesites en este cupón
          </p>
        </div>
        <CreateEditCoupon coupon={coupon} onSuccess={handleClose} onCancel={() => setShowModal(false)} />
      </div>,

    );
    setShowModal(true);
  };

  const handleCopyCouponLink = (coupon: CouponDTO) => {
    navigator.clipboard
      .writeText(`${config.baseDomain}/events/${eventId}?coupon=${coupon.code}`)
      .then(() => {
        toast.success('Link copiado al portapapeles');
      })
      .catch((err) => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  const dropDownOptions = [
    {
      label: 'Copiar link',
      onClick: handleCopyCouponLink,
    },
    {
      label: 'Editar',
      onClick: handleEditCoupon,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteCoupon,
    },
  ];

  const tableConfig = [
    {
      label: 'Código',
      id: 'code',
    },
    {
      label: 'Estado',
      id: 'active',
    },
    {
      label: 'Cantidad',
      id: 'amount',
    },

    {
      label: 'Descuentos',
      id: 'value',
    },
  ];

  const actions = (row: TableCoupons) => (
    <div className='w-fit mx-auto'>
      <MenuDropdown<CouponDTO>
        options={dropDownOptions}
        data={coupons.find((c) => c.id === row.id)!}
        loading={resultsDelete.isLoading}
      />
    </div>
  );

  const rows: TableCoupons[] = coupons.map((coupon) => ({
    id: coupon.id,
    code: coupon.code,
    active: coupon.active ? (
      <div className='flex flex-row gap-2'>
        <div className='bg-primary-base w-fit p-1 rounded-lg text-white text-medium'>
          Habilitado
        </div>
        {coupon.limit_amount - coupon.taken_amount <= 10 && <div className='bg-secondary-base text-white text-medium w-fit p-1 rounded-lg'>
          Quedan pocos
        </div>}
      </div>

    ) : (
      <div className='bg-warning-light w-fit p-1 rounded-lg text-gray-900 text-medium'>
        No habilitado
      </div>
    ),
    amount: `${coupon.taken_amount} / ${coupon.limit_amount}`,
    value: coupon.type === 'percentage' ? `${coupon.value * 100}%` : `$${coupon.value}`,

  }));

  return (
    <div>
      <FilterTable
        rows={rows}
        headCells={tableConfig}
        pagination={{
          enabled: true,
          pageSize: 10,
        }}
        searchEnabled={true}
        actions={actions}
      />
      <CustomModal
        onRequestClose={handleClose}
        isOpen={showModal}
        hideCancelButton={true}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default CouponsTable;
