import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ChipProps extends Omit<MuiChipProps, 'color'> {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info' | 'default';
  variant?: 'filled' | 'outlined';
}

const StyledChip = styled(MuiChip)(({ theme }) => ({
  borderRadius: '16px',
  fontSize: '0.875rem',
  height: '32px',
  '&.MuiChip-filled': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&.MuiChip-outlined': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

const Chip = ({ color = 'primary', variant = 'filled', ...props }: ChipProps) => (
    <StyledChip
      color={color}
      variant={variant}
      {...props}
    />
);

export default Chip;
