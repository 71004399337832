import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Input from './atoms/Input';
import Switch from './atoms/Switch';
import Button from './atoms/Button';
import {
  setSelectedBusiness,
  useCreateBusinessMutation,
  useGetBusinessByIdQuery,
  useUpdateBusinessMutation,
} from '../store';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import LoadingLottie from './atoms/LoadingLottie';
import { CreateBusinessDTO } from '../models/business';

interface Props {
  create: boolean;
}

interface FormData {
  name: string;
  description: string;
  active: boolean;
  nickname: string | undefined;
  has_mp_split: boolean;
  online_service_charge: number;
  manual_service_charge: number;
  is_reliable: boolean;
}

const validationSchema = yup.object<FormData>().shape({
  name: yup.string().required('Debes ingresar un nombre'),
  description: yup.string().required('Debes ingresar una descripción'),
  active: yup.boolean().required(),
  nickname: yup.string(),
  has_mp_split: yup.boolean().required(),
  online_service_charge: yup.number().required('Debes ingresar una comisión'),
  manual_service_charge: yup.number().required('Debes ingresar una comisión'),
  is_reliable: yup.boolean().required(),
});

const BusinessEditor: React.FC<Props> = ({ create }) => {
  const navigate = useNavigateWithQuery();
  const { business_id: businessId } = useParams<{ business_id: string }>();
  const { data: retrievedBusiness, isLoading } = useGetBusinessByIdQuery(+(businessId || 0), { skip: !businessId || create });
  const [business, setBusiness] = useState<FormData | null>(null);
  const [hasDomainSetup, setHasDomainSetup] = useState(false);

  useEffect(() => {
    if (create) {
      setBusiness(null);
    } else if (retrievedBusiness) {
      setBusiness({
        name: retrievedBusiness.name,
        description: retrievedBusiness.description,
        active: retrievedBusiness.active,
        nickname: retrievedBusiness.nickname,
        has_mp_split: retrievedBusiness.has_mp_split,
        online_service_charge: retrievedBusiness.online_service_charge,
        manual_service_charge: retrievedBusiness.manual_service_charge,
        is_reliable: retrievedBusiness.is_reliable,
      });
      console.log({ retrievedBusiness });
    }
    setHasDomainSetup(!!retrievedBusiness?.nickname);
  }, [create, retrievedBusiness]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      description: '',
      active: true,
      has_mp_split: false,
      online_service_charge: 0,
      manual_service_charge: 0,
      is_reliable: false,
      nickname: undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (business) {
      reset({
        name: business?.name || '',
        description: business?.description || '',
        active: business ? business.active : true,
        nickname: business && hasDomainSetup ? business.nickname : undefined,
        has_mp_split: business ? business.has_mp_split : false,
        online_service_charge: business ? business.online_service_charge : 0,
        manual_service_charge: business ? business.manual_service_charge : 0,
        is_reliable: business ? business.is_reliable : false,
      });
    }
  }, [business, reset]);

  const [createBusiness, resCreateBusiness] = useCreateBusinessMutation();
  const [updateBusiness, resUpdateBusiness] = useUpdateBusinessMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resCreateBusiness.isSuccess || resUpdateBusiness.isSuccess) {
      toast.success(`Productora ${create ? 'creada' : 'actualizada'} con éxito`, {
        duration: 5000,
        position: 'bottom-center',
      });
      if (resUpdateBusiness.data?.data) {
        dispatch(setSelectedBusiness(resUpdateBusiness.data.data));
      }
      navigate('..');
    }
  }, [resCreateBusiness.isSuccess, resUpdateBusiness.isSuccess]);

  const formDataToDto = (data: FormData): CreateBusinessDTO => ({
    name: data.name,
    description: data.description,
    active: data.active,
    has_mp_split: data.has_mp_split,
    nickname: data.nickname,
    online_service_charge: data.online_service_charge,
    manual_service_charge: data.manual_service_charge,
    is_reliable: data.is_reliable,
  });

  const onSubmit = async (data: FormData) => {
    try {
      if (business) {
        await updateBusiness({
          business: { ...formDataToDto(data), id: +(businessId || 0) },
        });
      } else {
        await createBusiness({
          business: formDataToDto(data),
        });
      }
    } catch (e) {
      console.error(e);
      toast.error('Ocurrió un error', {
        duration: 5000,
        position: 'bottom-center',
      });
    }
  };

  if (isLoading) {
    return <LoadingLottie />;
  }

  return (
    <div className="px-5 my-5">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <Input
          {...register('name')}
          id="name"
          label="Nombre"
          labelAbove={true}
          errMsg={errors.name?.message}
        />
        <Input
          {...register('description')}
          id="description"
          label="Descripción"
          labelAbove={true}
          errMsg={errors.description?.message}
        />
        <Input
          {...register('online_service_charge')}
          id="online_service_charge"
          label="Service charge online"
          labelAbove={true}
          errMsg={errors.online_service_charge?.message}
        />
        <Input
          {...register('manual_service_charge')}
          id="manual_service_charge"
          label="Service charge manual"
          labelAbove={true}
          errMsg={errors.manual_service_charge?.message}
        />
        <div className="mb-4">
          <Switch
            label="Activo"
            labelClassName="text-gray-900"
            checked={watch('active')}
            onChange={(e) => setValue('active', e.target.checked)}
          />
        </div>
        <div className="mb-4">
          <Switch
            label="Mercado Pago Split"
            labelClassName="text-gray-900"
            checked={watch('has_mp_split')}
            onChange={(e) => setValue('has_mp_split', e.target.checked)}
          />
        </div>
        <div className="mb-4">
          <Switch
            label="Negocio confiable"
            labelClassName="text-gray-900"
            checked={watch('is_reliable')}
            onChange={(e) => setValue('is_reliable', e.target.checked)}
          />
        </div>
        <div className="mb-4">
          <Switch
            label="Tiene dominio configurado"
            labelClassName="text-gray-900"
            checked={hasDomainSetup}
            onChange={(e) => {
              setHasDomainSetup(e.target.checked);
              if (!e.target.checked) {
                setValue('nickname', '');
              }
            }}
          />
        </div>
        {hasDomainSetup && (
          <Input
          {...register('nickname')}
          id="nickname"
          label="Dominio"
          labelAbove={true}
            errMsg={errors.nickname?.message}
          />
        )}
        <div className="flex flex-col justify-between">
          <Button
            type="submit"
            variant="primary"
            className="mb-2"
            disabled={!isValid}
            loading={resCreateBusiness.isLoading || resUpdateBusiness.isLoading}
          >
            Guardar cambios
          </Button>
          <Button
            type="button"
            variant="tertiary"
            onClick={() => navigate('..')}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BusinessEditor;
