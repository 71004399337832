import { FC } from 'react';

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <span className="text-red-500 white-text-shadow text-sm mt-1">{message}</span>
  );
};

export default ErrorMessage;
