import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from './atoms/Input';
import UserDTO from '../models/user';
import Select from './atoms/Select/Select';
import Button from './atoms/Button';
import {
  useAddUserToEventMutation,
  useUpdateUserEventMutation,
} from '../store';
import { toastSucces } from '../utils/toasts';

interface Props {
  businessId: number;
  eventId: number;
  users: UserDTO[];
  onSuccess: () => void;
  sellerToEdit?: {
    userId: number;
    comissionRate: number;
  };
  onClose?: () => void;
}

interface FormData {
  user_id: number;
  comission_rate: number;
}

const validationSchema = yup.object<FormData>().shape({
  user_id: yup.number().required('Debes seleccionar un usuario'),
  comission_rate: yup
    .number()
    .required('Debes seleccionar una opción')
    .min(0, 'La comisión debe ser mayor a 0')
    .max(100, 'La comisión debe ser menor a 100'),
});

const AddEditSellerToEvent: React.FC<Props> = ({
  users, businessId, eventId, onSuccess, sellerToEdit, onClose,
}) => {
  const form = useForm<FormData>({
    resolver: yupResolver<FormData>(validationSchema),
    defaultValues: sellerToEdit ? {
      user_id: sellerToEdit.userId,
      comission_rate: sellerToEdit.comissionRate * 100,
    } : undefined,
  });
  const { control, handleSubmit, formState } = form;
  const [addUserToEvent, addUserToEventResults] = useAddUserToEventMutation();
  const [editUserInEvent, editUserInEventResults] = useUpdateUserEventMutation();
  const handleAddUser = async () => {
    try {
      const values = form.getValues();
      if (sellerToEdit) {
        await editUserInEvent({
          businessId,
          eventId,
          data: {
            id: sellerToEdit.userId,
            seller_comission_rate: values.comission_rate / 100,
          },

        });
      } else {
        await addUserToEvent({
          businessId,
          eventId,
          userData: {
            userId: values.user_id,
            comissionRate: values.comission_rate / 100,
          },
        });
      }
      toastSucces(sellerToEdit ? 'Vendedor actualizado' : 'Vendedor agregado al evento');
      onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleAddUser)}>
        {!sellerToEdit && (
          <div className="mb-5">
            <Controller
              name="user_id"
              control={control}
              render={({ field }) => (
                <Select
                  label="Usuario"
                  id="user_id"
                  placeholder="Selecciona un usuario"
                  options={users.map((user) => ({
                    label: user.username,
                    value: user.id,
                  }))}
                  {...field}
                  errMsg={formState.errors.user_id?.message}
                  disabled={!!sellerToEdit}
                />
              )}
            />
          </div>
        )}
        <div className="mb-5">
          <Controller
            name="comission_rate"
            control={control}
            render={({ field }) => (
              <Input
                label="Comisión"
                labelAbove={true}
                id="comission_rate"
                {...field}
                onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                }
                errMsg={formState.errors.comission_rate?.message}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center mb-1 gap-2 w-full">
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={addUserToEventResults.isLoading || editUserInEventResults?.isLoading}
          >
            {sellerToEdit ? 'Actualizar' : 'Enviar'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddEditSellerToEvent;
