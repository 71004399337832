import { useEffect } from 'react';
import { HiLink } from 'react-icons/hi';
import { FaBalanceScaleLeft } from 'react-icons/fa';
import EventMenuOption from '../components/EventMenuOption';
import authenticate from '../utils/authenticate';
import { UserRole } from '../models/user';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import LoadingLottie from '../components/atoms/LoadingLottie';
import copyLinkToClipboard from '../utils/copyLinkToClipboard';
import config from '../config/config';
import AdminSectionTitle from '../components/AdminSectionTitle';

interface Options {
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  allowedUsers: UserRole[];
  hidden?: boolean;
  image: string;
}

const EventMenu: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const { event, eventLoading } = useGetSelectedEvent();
  const role = useGetUserRole();

  useEffect(() => {
    if (role === 'SELLER') {
      navigate('orders', { replace: true });
    } else if (role === 'SCANNER') {
      navigate('scanner', { replace: true });
    }
  }, [role, navigate]);

  const options: Options[] = [
    {
      title: 'Asistentes',
      info: 'En este apartado encontrarás la acción de aceptar solicitudes de entradas, y podrás visualizar los asistentes al evento.',
      buttonLabel: 'Ver lista',
      redirectPath: 'orders',
      allowedUsers: ['EDITOR', 'ADMIN'],
      image: '/event-orders.svg',
    },
    {
      title: 'Entradas',
      info: 'Aquí podras crear los diferentes tipos de entradas de tu evento, configurar su precio y cantidad de cada una.',
      buttonLabel: 'Editar entradas',
      redirectPath: 'tickets',
      allowedUsers: ['ADMIN'],
      image: '/event-tickets.svg',
    },
    {
      title: 'Reportes',
      info: 'En este apartado podrás descargar reportes de asistencia, pagos y respuestas de encuestas.',
      buttonLabel: 'Ver reportes',
      redirectPath: 'sales-report',
      allowedUsers: ['ADMIN'],
      image: '/event-polls.svg',
    },
    {
      title: 'Cupones',
      info: 'Aquí podras crear y modificar los cupones de descuento de tu evento.',
      buttonLabel: 'Editar cupones',
      redirectPath: 'coupons',
      allowedUsers: ['ADMIN'],
      image: '/event-coupons.svg',
    },
    {
      title: 'Referidos',
      info: 'Aquí ver y configurar los referidos del evento.',
      buttonLabel: 'Ver referidos',
      redirectPath: 'referrals',
      allowedUsers: ['ADMIN'],
      image: '/event-referrals.svg',
    },
    {
      title: 'Encuestas',
      info: 'Aquí podras ver las respuestas a las preguntas de tu evento.',
      buttonLabel: 'Ver respuestas',
      redirectPath: 'polls',
      allowedUsers: ['ADMIN'],
      hidden: !event?.has_polls,
      image: '/event-svg-1.svg',
    },
    {
      title: 'Vendedores',
      info: 'Aquí podras configurar los vendedores de este evento y ver sus ventas',
      buttonLabel: 'Ver vendedores',
      redirectPath: 'sellers',
      allowedUsers: ['ADMIN'],
      image: '/event-sellers.svg',
    },
  ];

  if (!event || eventLoading) {
    return <LoadingLottie />;
  }

  const handleBalanceClick = () => {
    navigate('balance');
  };

  const handleManualSellClick = () => {
    navigate(`/admin/${event.business_id}/events/${event.id}/orders/new/tickets`);
  };

  const handleScannerClick = () => {
    navigate('scanner');
  };

  const handleEditEventClick = () => {
    navigate('edit');
  };

  return (
    <div className="flex flex-col gap-8 my-6">
      <div className="grid grid-cols-1 justify-between gap-4">
        <AdminSectionTitle
          className="col-span-1 md:col-span-4"
          title="Eventos"
          subtitle={`${event?.name}`}

          mainButtons={[
            ...(role === 'ROOT' || role === 'ADMIN' ? [{
              label: 'Vender manualmente',
              onClick: () => handleManualSellClick(),
              variant: 'secondary' as const,
              image: '/coins-hand.svg',
            }] : []),
            ...(role === 'ROOT' || role === 'ADMIN' || role === 'SCANNER' ? [{
              label: 'Abrir Lector de entradas',
              onClick: () => handleScannerClick(),
              variant: 'secondary' as const,
              image: '/qr-code-02.svg',
            }] : []),
            ...(role === 'ROOT' || role === 'ADMIN' ? [{
              label: 'Editar evento',
              onClick: () => handleEditEventClick(),
              variant: 'secondary' as const,
              image: '/edit-3.svg',
            }] : []),
          ]}

          sideButtons={[{
            onClick: () => copyLinkToClipboard(`${config.baseDomain}/events/${event.id}`),
            variant: 'tertiary',
            icon: <HiLink className="text-xl" />,
          },
          ...(role === 'ROOT' ? [{
            onClick: () => handleBalanceClick(),
            variant: 'tertiary' as const,
            icon: <FaBalanceScaleLeft className="text-xl" />,
          }] : []),
          ]}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {options.map(
          (option) => !option.hidden && (
            <div key={option.title} className="mb-3">
              {authenticate(option.allowedUsers, role) && (
                <EventMenuOption
                  id={event?.id ?? 0}
                  title={option.title}
                  info={option.info}
                  buttonLabel={option.buttonLabel}
                  redirectPath={option.redirectPath}
                  image={option.image}
                />
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default EventMenu;
