import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetEventBalanceQuery, useCreateWireTransferMutation } from '../store';
import LoadingLottie from '../components/atoms/LoadingLottie';
import useGetParams from '../hooks/useGetParams';
import TitleTextCard from '../components/atoms/TitleTextCard';
import { formatCurrency } from '../utils/shared';
import SortableTable from '../components/atoms/SortableTable';
import { WireTransferDTO } from '../models/wire-transfer';
import Button from '../components/atoms/Button';
import CustomModal from '../components/atoms/CustomModal';
import Input from '../components/atoms/Input';

interface WireTransferForm {
  amount: number;
  description: string;
}

const EventBalancePage = () => {
  const [showModal, setShowModal] = useState(false);
  const { businessId, eventId } = useGetParams();
  const { data: balance, isLoading: balanceLoading } = useGetEventBalanceQuery({ businessId, eventId }, { skip: !businessId || !eventId });
  const [createWireTransfer] = useCreateWireTransferMutation();

  const {
    register, handleSubmit, reset, formState: { errors },
  } = useForm<WireTransferForm>({
    defaultValues: {
      amount: 0,
      description: '',
    },
  });

  if (balanceLoading) {
    return <LoadingLottie />;
  }

  const config = [
    {
      label: 'Usuario',
      render: (user: WireTransferDTO) => (user.created_by),
    },
    {
      label: 'Importe',
      render: (amount: WireTransferDTO) => formatCurrency(amount.amount),
    },
    {
      label: 'Observaciones',
      render: (description: WireTransferDTO) => description.description,
    },
  ];

  const handleAddTransfer = () => {
    setShowModal(true);
  };

  const onSubmit = (data: WireTransferForm) => {
    createWireTransfer({
      wire: {
        amount: data.amount,
        description: data.description,
      },
      businessId,
      eventId,
    });
    setShowModal(false);
    reset();
  };

  return (
    <div className="px-3 h-screen mt-2">
      <div>
        <TitleTextCard title="Balance de evento">
          <div className='font-bold'>Total Bruto: <span className='font-normal'>{formatCurrency(balance?.total_amount ?? 0)}</span></div>
          <div className='font-bold'>Total Descuentos: <span className='font-normal'>{formatCurrency(balance?.total_discounts ?? 0)}</span></div>
          <div className='font-bold'>Comisiones Avantti: <span className='font-normal'>{formatCurrency(balance?.avantti_fees ?? 0)}</span></div>
          <div className='font-bold'>Comisiones Gateway: <span className='font-normal'>{formatCurrency(balance?.gateway_fees ?? 0)}</span></div>
          <div className='font-bold'>Total Neto: <span className='font-normal'>{formatCurrency(balance?.net_total ?? 0)}</span></div>
          <div className='font-bold'>Total en manos de la productora: <span className='font-normal'>{formatCurrency(balance?.total_in_business_accounts ?? 0)}</span></div>
          <div className='font-bold'>Total transferido: <span className='font-normal'>{formatCurrency(balance?.total_transferred ?? 0)}</span></div>
          <div className='font-bold text-lg font underline underline-offset-2'>Balance total: <span className='font-normal'>{formatCurrency(balance?.total_balance ?? 0)}</span></div>
        </TitleTextCard>
      </div>
      <div className="card border border-gray-200 mt-2">
        <div className="flex py-2 justify-between">
          <h1 className="text-lg">Transferencias</h1>
          <div className="w-12">
          <Button onClick={handleAddTransfer} variant="secondary">
            {' '}
            +{' '}
          </Button>
          </div>
        </div>
        <Fragment>
          <SortableTable data={balance?.wires || []} config={config} keyFn={(item) => item.id.toString()} pagination={false} search={false}/>
        </Fragment>
        <div>
          <CustomModal
            onRequestClose={() => {
              setShowModal(false);
              reset();
            }}
            isOpen={showModal}
            actionBar={<div></div>}
            hideCancelButton
          >
            <div>
              <h1>Agregar transferencia</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  {...register('amount', {
                    required: 'El importe es requerido',
                    min: { value: 0, message: 'El importe debe ser mayor a 0' },
                  })}
                  id="amount"
                  label="Importe"
                  labelAbove={true}
                  errMsg={errors.amount?.message}
                  type="number"
                />
                <Input
                  {...register('description', {
                    required: 'La descripción es requerida',
                    minLength: { value: 3, message: 'La descripción debe tener al menos 3 caracteres' },
                  })}
                  id="description"
                  label="Descripción"
                  labelAbove={true}
                  errMsg={errors.description?.message}
                />
                <Button variant="primary" className='w-full' type="submit">
                  Agregar
                </Button>
              </form>
            </div>
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default EventBalancePage;
