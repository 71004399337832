import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import LoadingLottie from '../components/atoms/LoadingLottie';
import TextTitleCard from '../components/atoms/TitleTextCard';
import useGetParams from '../hooks/useGetParams';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import { formatCurrency } from '../utils/shared';
import { useGetEventOrdersReportQuery, RootState } from '../store';
import TicketsSummaryTable from '../components/SellersTicketSummary';
import { EventSalesReportDTO } from '../models/event';
import SortableTable from '../components/atoms/SortableTable';
import AdminSectionTitle from '../components/AdminSectionTitle';
import config from '../config/config';
import Button from '../components/atoms/Button';
import useGetUserRole from '../hooks/useGetUserRole';

interface SalesArray {
  title: string;
  count: number | string;
  total: number;
}

const salesReportToTableArray = (reportData: EventSalesReportDTO) => {
  const tableArray: SalesArray[] = [
    {
      title: 'Venta online',
      count: reportData.tickets_count_online,
      total: reportData.total_amount_online,
    },
    {
      title: 'Venta manual',
      count: reportData.tickets_count_manual,
      total: reportData.total_amount_manual_with_service_charge,
    },
    {
      title: 'Comisiones venta manual',
      count: '-',
      total: reportData.total_manual_service_charge,
    },
    {
      title: 'Total',
      count: reportData.total_tickets_count,
      total: reportData.total_amount,
    },
  ];
  return tableArray;
};

const EventSalesReportPage: React.FC = () => {
  const [salesArray, setSalesArray] = useState<SalesArray[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const { eventId, businessId } = useGetParams();
  const { event } = useGetSelectedEvent();
  const { token } = useSelector((state: RootState) => state.auth);
  const { data: reportData, isLoading } = useGetEventOrdersReportQuery({
    businessId,
    eventId,
  });
  const role = useGetUserRole();

  useEffect(() => {
    if (reportData) {
      setSalesArray(salesReportToTableArray(reportData));
    }
  }, [reportData]);

  if (isLoading) {
    return <LoadingLottie />;
  }
  if (!reportData) {
    return <div>No hay datos</div>;
  }

  const tableConfig = [
    {
      label: 'Canal de venta',
      render: (s: SalesArray) => s.title,
    },
    {
      label: 'Cantidad',
      render: (s: SalesArray) => s.count,
    },
    {
      label: 'Total',
      render: (s: SalesArray) => formatCurrency(s.total),
    },
  ];

  const handleDownloadExport = () => {
    setIsDownloading(true);
    axios
      .get(
        `${config.baseURL}/v1/businesses/${businessId}/events/${eventId}/payments?xlsx=true`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `payments_report_${new Date().toISOString()}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsDownloading(false));
  };

  return (
    <div className="flex flex-col gap-8 my-6">
      <AdminSectionTitle
        title={'Eventos'}
        subtitle={`${event?.name} - Reportes`}
      />
      <div>
        <TextTitleCard
          title="Resumen de ventas"
          subtitle="Monto neto por canal de ventas, después de aplicar descuentos."
        >
          <SortableTable
            data={salesArray}
            config={tableConfig}
            keyFn={(s) => s.title}
            pagination={false}
            search={false}
          />
        </TextTitleCard>
      </div>
      <div>
        <TextTitleCard
          title="Resumen de ventas por tipo de entrada"
          subtitle="Monto bruto de ventas por tipo de entrada."
        >
          <TicketsSummaryTable summaries={reportData.ticket_summary} />
        </TextTitleCard>
      </div>
      {role === 'ROOT' && (
        <div className="flex align-items-center justify-center">
          <TextTitleCard title="Descargar reporte">
            <span className="mb-4">
              Apretando el siguiente botón podés descargar un reporte de ventas
              del evento en formato excel.
            </span>
            <Button onClick={handleDownloadExport} loading={isDownloading}>

              Descargar reporte pagos
            </Button>
          </TextTitleCard>
        </div>
      )}
    </div>
  );
};

export default EventSalesReportPage;
