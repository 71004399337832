// eslint-disable-next-line import/no-cycle
import { BusinessDTO } from './business';
// eslint-disable-next-line import/no-cycle
import { TicketSummaryDTO } from './order';
import { PaymentDTO } from './payment';
import TicketDTO from './ticket';
import { WireTransferDTO } from './wire-transfer';

export default interface EventDTO {
  id:number,
  business_id: number,
  name: string,
  description: string,
  date: string,
  time: string,
  place: string,
  active: boolean,
  terms: string,
  phone: string,
  tickets: TicketDTO[]
  banner_image: string;
  maps_link: string;
  allow_online_payments: boolean;
  allow_manual_payments: boolean;
  online_service_charge: number;
  manual_service_charge: number;
  min_age: number;
  address: string;
  require_id_tickets: boolean;
  business: BusinessDTO;
  receives_wire_transfers: boolean;
  has_polls: boolean;
  is_public: boolean;
  has_numerated_seats: boolean;
  tickets_includes_service_charge: boolean;
  has_seller_qr: boolean;
  has_scanner_auto_accept: boolean;
  has_order_qrs: boolean;
  is_important: boolean;
  is_approved: boolean;
  public_display: boolean;
  banner_display: boolean;
  public_status: EventPublicStatus;
  seller_sales_enabled: boolean;
}

export interface SellerEventQR {
  qr_code: string;
  qr_image_b64: string;
}

export interface EventSalesReportDTO {
  total_amount_manual: number;
  total_amount_online: number;
  total_amount: number;
  tickets_count_manual: number;
  tickets_count_online: number;
  total_tickets_count: number;
  ticket_summary: TicketSummaryDTO[];
  total_manual_service_charge: number;
  total_amount_manual_with_service_charge: number;
}

export interface EventBalanceDTO {
  total_amount: number;
  total_discounts: number;
  net_total: number;
  avantti_fees: number;
  gateway_fees: number;
  total_in_business_accounts: number;
  total_transferred: number;
  total_balance: number;
  payments: PaymentDTO[];
  wires: WireTransferDTO[];
}

export enum EventPublicStatus {
  Ready = 'ready',
  PendingApproval = 'pending_approval',
  NoTicketsAvailable = 'no_tickets_available',
  Expired = 'expired',
  Inactive = 'inactive',
}
