import { Controller, useForm } from 'react-hook-form';
import { useGetBusinessEventsQuery, useImportSellersToEventMutation } from '../store';
import Select from './atoms/Select/Select';
import Button from './atoms/Button';
import { toastSucces } from '../utils/toasts';

interface Event {
  id: number;
  name: string;
}

interface FormValues {
  event_id: number;
}

interface Props {
  businessId: number;
  eventId: number;
  onSuccess: () => void;
}

const ImportSellersToEvent: React.FC<Props> = ({ businessId, eventId, onSuccess }) => {
  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      event_id: undefined,
    },
  });
  const { data } = useGetBusinessEventsQuery({ businessId });
  const [importSellers, { isLoading }] = useImportSellersToEventMutation();
  const events = (data?.data || []) as Event[];

  const handleImportSellers = async (formData: FormValues) => {
    try {
      await importSellers({ businessId, eventId, eventIdToImport: formData.event_id }).unwrap();
      toastSucces('Vendedores importados exitosamente');
      onSuccess();
    } catch (error) {
      console.error('Error importing sellers:', error);
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleImportSellers)}>
        <div className="mb-5">
          <Controller
            control={control}
            name="event_id"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label="Eventos"
                id="event_id"
                placeholder="Selecciona un evento"
                options={events.filter((event: Event) => event.id !== eventId).map((event: Event) => ({
                  label: event.name,
                  value: event.id,
                }))}
                {...field}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center mb-1">
          <Button
            className="w-full"
            variant="primary"
            type="submit"
            loading={isLoading}
            disabled={!formState.isValid}
          >
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ImportSellersToEvent;
