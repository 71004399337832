interface Props {
  tabs: {
    label: string;
    onClick: () => void;
    selected: boolean;
  }[];
}

const HorizontalTabs = ({ tabs }: Props) => (
  <div className="grid grid-cols-2 gap-1">
    {tabs.map((tab, index) => (
      <div
        key={index}
        className={`w-full h-full py-2 px-3 text-center rounded-[6px] hover:cursor-pointer hover:text-secondary-dark ${tab.selected ? 'bg-gray-100 text-secondary-dark' : 'text-grey-700'}`}
        onClick={tab.onClick}
      >
        <p>{tab.label}</p>

      </div>
    ))}

  </div>
);

export default HorizontalTabs;
