import { useEffect, useState } from 'react';
import { useGetAllEventsQuery } from '../store';
import LandingEventCard from '../components/LandingEventCard';
import EventDTO from '../models/event';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
// import Input from '.../components/atoms/Carrousel/Carrousel
import LottieAnimation from '../components/atoms/LottieAnimation';
import AvailableEventsPageSkeleton from '../components/AvailableEventsPageSkeleton';
import Carrousel from '../components/atoms/Carrousel/Carrousel';
// import Button from '../components/atoms/Button';

interface Props {
  loadedEvents?: EventDTO[];
}

const AvailableEventsPage: React.FC<Props> = ({ loadedEvents }) => {
  const navigate = useNavigateWithQuery();
  const { data: events, isLoading } = useGetAllEventsQuery(
    { },
    { skip: !!loadedEvents },
  );

  const [sortedEvents, setSortedEvents] = useState<EventDTO[]>([]);
  const [images, setImages] = useState<{ url: string; eventId: number }[]>([]);
  // const [showAll, setShowAll] = useState(false);
  // const [visibleEvents, setVisibleEvents] = useState<EventDTO[]>([]);

  // const handleResize = () => {
  //   const size = window.innerWidth >= 930;
  //   setShowAll(size);
  //   setVisibleEvents(size ? sortedEvents : sortedEvents.slice(0, 3));
  // };
  useEffect(() => {
    const eventsToShow = loadedEvents || events;
    if (eventsToShow) {
      const sorted = [...eventsToShow]
        .filter((e) => e.public_display)
        .sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );
      setSortedEvents(sorted);
      const bannerEvents = eventsToShow.filter((event) => event.banner_display);
      setImages(bannerEvents.map((event) => ({
        url: event.banner_image,
        eventId: event.id,
      })));
      // setVisibleEvents(window.innerWidth >= 930 ? sorted : sorted.slice(0, 3));
    }
  }, [events, loadedEvents]);

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [sortedEvents]);

  const handleEventSelect = (eventId: number) => {
    navigate(`/events/${eventId}`);
  };

  // useEffect(() => {
  //   setVisibleEvents(showAll ? sortedEvents : sortedEvents.slice(0, 3));
  // }, [showAll, sortedEvents]);

  return (
    <div className="flex flex-col gap-6 py-5 h-full">
      {isLoading
      && <AvailableEventsPageSkeleton />}
      <div className="p-1">
        {!isLoading && images.length !== 0 && (
          <Carrousel
            images={images}
            isArrow={true}
            onImageClick={(eventId) => handleEventSelect(eventId)}
          />
        )}
      </div>
      <div>
        {/* <div className="my-12 w-full lg:w-1/2">
          <Input id={'search'} label={'Buscar'} isSearch={true} />
        </div> */}
        <LottieAnimation height='30px' path="/cards.json" className="w-full h-10" slice />
      </div>
      {/* <div className="flex flex-row my-12 items-center gap-4">
        <img src="/dot-pink.svg" />
        <img src="/black-square.svg" />
        <span className="text-xl-semibold lg:display-md-semibold text-grey-700">
          Nuestros elegidos para el fin de semana
        </span>
      </div>
      <div className="flex mb-20 gap-6 items-center flex-wrap">
        {!isLoading
          && sortedEvents.length > 0
          && sortedEvents.map((event) => (
            <div key={event.id} className="w-[376px] m-1">
              <LandingEventCard event={event} onSelect={handleEventSelect} />
            </div>
          ))}
      </div>
        ))}
      </div> */}
      {/* {!showAll && sortedEvents.length > 3 && (
        <div className="flex justify-center mb-10 lg:hidden">
          <Button variant="tertiary" onClick={() => setShowAll(true)}>
            Ver más eventos
          </Button>
        </div>
      )} */}
      <div className="flex flex-row items-center gap-4">
        <span className="text-xl-semibold lg:display-md-semibold text-grey-700">
          Próximos eventos
        </span>
        <img src="/violet-moon.svg" />
        <img src="/black-flower.svg" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-20 gap-6 items-center flex-wrap">
        {!isLoading
          && sortedEvents.map((event) => (
            <div key={event.id} className="">
              <LandingEventCard event={event} onSelect={handleEventSelect} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AvailableEventsPage;
