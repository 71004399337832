import { createApi } from '@reduxjs/toolkit/query/react';
import { Response } from '../../models/shared';
import baseQueryWithReauth from './base';

const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getMPAuthLink: build.query<{ auth_link: string } | null, { business_id: number }>({
      query: ({ business_id }) => ({
        url: `payments/mp-auth-link/${business_id}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<{ auth_link: string }>): ({ auth_link: string } | null) => response.data || null,
    }),
    uploadImage: build.mutation<string, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('image', file);
        return {
          url: 'shared/upload-image',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      transformResponse: (response: Response<{ url: string }>): string => response.data?.url || '',
    }),
  }),
});

export const {
  useGetMPAuthLinkQuery,
  useUploadImageMutation,
} = commonApi;
export { commonApi };
