import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import EventMenuOption from '../components/EventMenuOption';
import { UserType } from '../models/user';
import {
  RootState,
  setSelectedBusiness,
  useGetAllBusinessesQuery,
  useGetUserBusinessesQuery,
} from '../store';
import Button from '../components/atoms/Button';
import LoadingLottie from '../components/atoms/LoadingLottie';
import { BusinessDTO } from '../models/business';
import IconInfoCard from '../components/atoms/IconInfoCard';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import Switch from '../components/atoms/Switch';
import Input from '../components/atoms/Input';
import Select from '../components/atoms/Select/Select';

interface Options {
  id: number;
  title: string;
  info: string;
  buttonLabel: string;
  redirectPath: string;
  allowedUsers: UserType[];
}

const BusinessMenu: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();
  const [showInactiveBusinesses, setShowInactiveBusinesses] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<string>('');

  const { data: allBusinesses, isLoading: allBizLoading } = useGetAllBusinessesQuery(
    { query: { active: showInactiveBusinesses ? undefined : true } },
    {
      skip: !user || user.type !== 'ROOT',
    },
  );
  const { data: userBusinesses, isLoading: userBizLoading } = useGetUserBusinessesQuery(
    { userId: user.id },
    { skip: !user || !user.id || user.type === 'ROOT' },
  );
  const [businesses, setBusinesses] = useState<BusinessDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Options[]>([]);

  useEffect(() => {
    const baseBusiness = allBusinesses || userBusinesses || [];
    let baseBusinesses = baseBusiness;
    if (!searchTerm && !showInactiveBusinesses) {
      baseBusinesses = baseBusiness.filter((b) => b.active);
    }

    const filteredBusinesses = baseBusinesses.filter((business) => business.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const sortedBusinesses = [...filteredBusinesses].sort((a, b) => {
      if (sortBy === 'name') {
        return a.name.localeCompare(b.name);
      }
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });

    setBusinesses(sortedBusinesses);
    setIsLoading(allBizLoading || userBizLoading);
  }, [allBusinesses, userBusinesses, allBizLoading, userBizLoading, showInactiveBusinesses, searchTerm, sortBy]);

  useEffect(() => {
    setOptions(
      businesses.map((b) => ({
        id: b.id,
        title: b.name,
        info: b.description,
        buttonLabel: 'Seleccionar',
        redirectPath: `${b.id}/events`,
        allowedUsers: ['ROOT', 'COMMON'],
      })),
    );
  }, [businesses]);

  if (isLoading) {
    return <LoadingLottie />;
  }

  const handleButtonClick = (id: number) => {
    const selected = allBusinesses?.find((b) => b.id === id);
    console.log({ selected, businesses: allBusinesses });
    if (selected) {
      dispatch(setSelectedBusiness(selected));
    }
  };

  return (
    <div className="flex py-8 px-1 flex-col gap-8 items-start self-stretch">
      <div className="flex items-center gap-4 self-stretch">
        <div className="text-3xl font-medium text-grey-700">Productoras</div>
        <img src={'/business-svg-1.svg'} alt="" />
        <img src={'/business-svg-2.svg'} alt="" />
      </div>
      {user.type === 'ROOT' && (
        <div className="flex flex-col md:flex-row p-4 justify-between self-stretch gap-4 items-center text-center bg-white border border-gray-100 rounded-lg shadow-sm">
          <div className="text-lg">Creá una nueva productora para administrar eventos</div>
          <div className="flex justify-center md:justify-between">
            <Button
              type="submit"
              variant="primary"
              className="mb-2"
              onClick={() => navigate('create-business')}
            >
              Crear nueva productora
            </Button>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full md:flex-row gap-4">
        <div className="w-full bg-white h-12">
          <Input
            id="search-event"
            label="Buscar productora"
            placeholder="Buscar productora"
            onChange={(b) => setSearchTerm(b.target.value)}
            value={searchTerm}
            isSearch
          />
        </div>
        <Select
          placeholder="Ordenar productoras"
          id="sort-businesses"
          label=""
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as 'name' | 'created_at')}
          options={[
            { value: 'name', label: 'Por nombre' },
            { value: 'created_at', label: 'Por fecha de creación' },
          ]}
        />
        <div className="w-full bg-white border border-gray-100 rounded-lg shadow-sm h-12 flex items-center px-3">
          <Switch
            label="Mostrar productoras inactivas"
            onChange={(e) => setShowInactiveBusinesses(e.target.checked)}
          />
        </div>
      </div>
      {businesses && businesses.length === 0 && !searchTerm && (
        <div className="flex flex-col items-center justify-center w-full">
          <IconInfoCard
            title="Todavía no tenés productoras asociadas"
            info="Aquí aparecerán las productoras asociadas a tu usuario"
            icon={HiOutlineRocketLaunch}
            iconColor="text-indigo-600"
          />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {options.map((option) => (
          <div className="w-full" key={option.id}>
            <EventMenuOption
              id={option.id}
              title={option.title}
              info={option.info}
              buttonLabel="Ver productora"
              redirectPath={option.redirectPath}
              onButtonClick={handleButtonClick}
              image="/logo.png"
            />
          </div>
        ))}
      </div>
      {searchTerm && !isLoading && businesses.length === 0 && (
        <div className="flex flex-col items-center justify-center w-full">
          <div className="text-lg text-center">
            <h1 className="text-2xl font-medium">¡No encontramos la productora que buscas!</h1>
            <p>
              Probá con una combinación diferente de filtros.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessMenu;
