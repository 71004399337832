import React from 'react';
import { BsArrowDownUp, BsArrowUp, BsArrowDown } from 'react-icons/bs';
import LegacyTable from './LegacyTable';
import useSort, { ColumnConfig } from '../../hooks/useHook';

interface SortableTableProps<T> {
  config: ColumnConfig<T>[];
  data: T[];
  keyFn: (data: T) => string | number;
  pagination: boolean;
  search: boolean;
}

function getIcons(
  label: string,
  sortBy: string | null,
  sortOrder: string | null,
) {
  if (label !== sortBy || sortOrder == null) {
    return (
      <div className='p-0.5'>
        <BsArrowDownUp />
      </div>
    );
  }
  if (sortOrder === 'desc') {
    return (
      <div className='p-0.5'>
        <BsArrowUp />
      </div>
    );
  }
  if (sortOrder === 'asc') {
    return (
      <div className='p-0.5'>
        <BsArrowDown />
      </div>
    );
  }
  return '';
}

function SortableTable<T>(props: SortableTableProps<T>) {
  const {
    config, data, pagination, search,
  } = props;

  const {
    sortBy, sortOrder, sortAttribute, dataSorted,
  } = useSort(
    config,
    data,
  );

  const newConfig = config.map((col) => {
    if (col.sortValue) {
      return {
        ...col,
        header: () => (
          <th
            className="cursor-pointer hover:bg-gray-100"
            onClick={() => sortAttribute(col.label)}
          >
            <div className="flex py-2 px-3">
              {col.label}
              {getIcons(col.label, sortBy, sortOrder)}
            </div>
          </th>
        ),
      };
    }
    return col;
  });

  return <LegacyTable {...props} config={newConfig} data={dataSorted} pagination={pagination} search={search}></LegacyTable>;
}

export default SortableTable;
