import { DateTime } from 'luxon';

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const parseFriendlyDate = (isoDate: string, capitalize?: boolean) => {
  const date = DateTime.fromISO(isoDate).setLocale('es');
  const dayOfWeek = date.toFormat('cccc'); // Obtiene el día de la semana con la primera letra en mayúscula
  const { day } = date; // Obtiene el día del mes sin ceros a la izquierda
  const month = date.toFormat('LLLL'); // Obtiene el nombre completo del mes

  return `${capitalize ? capitalizeFirstLetter(dayOfWeek) : dayOfWeek} ${day} de ${month}`;
};

const parseFriendlyDateAndHour = (isoDate: string) => DateTime.fromISO(isoDate).setLocale('es').toFormat('dd/MM - HH:mm');

const formatDateYYYYMMDD = (date: string | Date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const formatDateForInput = (dateString: string | null) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
  return adjustedDate.toISOString().slice(0, 16);
};

const combineDateAndTime = (date: string, time: string) => {
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');
  const combinedDate = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    parseInt(hours, 10),
    parseInt(minutes, 10),
  );
  return combinedDate.toISOString();
};

export {
  parseFriendlyDate, parseFriendlyDateAndHour, formatDateYYYYMMDD, formatDateForInput, combineDateAndTime,
};
