import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  clearReservation,
  RootState,
  useCreateOrderMutation,
  useGetCurrentAgentQuery,
} from '../store';
import OrderSummary from '../components/OrderSummary';
import Input from '../components/atoms/Input';
import config from '../config/config';
import GotCoupon from '../components/GotCoupon';
import CouponDTO from '../types/Coupon';
import useSendWhatsapp from '../hooks/useSendWhatsapp';
import generateWhatsappMessage from '../utils/generateWhatsappMessage';
import Button from '../components/atoms/Button';
import RadioButton from '../components/atoms/RadioButton';
import useQuery from '../hooks/useQuery';
import OpenPriceCard from '../components/OpenPriceCard';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import { noSpaces, emailRegex } from '../utils/regexs';
import { OrderData } from '../store/apis/orders';
import OrderTotalSkeleton from '../components/OrderTotalSkeleton';
import useGetUserRole from '../hooks/useGetUserRole';
import TermsAndConditions from '../components/TermsAndConditions';
import TermsAndServicesType from '../models/terms';

interface FormData {
  email: string;
  emailConfirm: string;
}

const validationSchema = yup.object<FormData>().shape({
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .matches(emailRegex, 'Este correo no está bien')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
  emailConfirm: yup
    .string()
    .required('Debes ingresar un correo')
    .oneOf([yup.ref('email')], 'Los correos no coinciden')
    .test('no-spaces', 'No se permiten espacios', noSpaces),
});

const CheckoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isBackoffice = location.pathname.includes('/admin/');

  const query = useQuery();
  const customized = query.get('customized') === 'true' || false;
  const skipPoll = query.get('skip-poll') === 'true' || false;
  const couponCode = query.get('coupon') ?? null;
  const refCode = query.get('ref') ?? null;
  const [orderData, setOrderData] = useState<OrderData | null>(null);

  const [appliedCoupon, setAppliedCoupon] = useState<CouponDTO | null>(null);
  const [openPriceAmount, setOpenPriceAmount] = useState<number | undefined>(
    undefined,
  );
  const [totalToPay, setTotalToPay] = useState<number>(0);
  const [clickedPay, setClickedPay] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [totalsLoading, setTotalsLoading] = useState(true);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const role = isBackoffice ? useGetUserRole() : null;

  const [createOrder, results] = useCreateOrderMutation();

  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const reservation = useSelector(
    (state: RootState) => state.orders.reservation,
  );

  const [paymentMethod, setPaymentMethod] = useState(
    event?.allow_online_payments ? 'online' : 'manual',
  );

  const { orderItemData, pollData } = useSelector(
    (state: RootState) => state.orders,
  );

  const { data: agent } = useGetCurrentAgentQuery(
    { businessId: event?.business_id || 0, eventId: event?.id || 0 },
    { skip: !event || !event.allow_manual_payments || isBackoffice },
  );

  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      emailConfirm: '',
    },
    resolver: yupResolver<FormData>(validationSchema),
  });

  const navigate = useNavigateWithQuery();

  useEffect(() => {
    if (!event || !orderItemData) {
      navigate('../../');
    }
  }, [event, orderItemData, navigate]);

  if (!event || !orderItemData) {
    return null;
  }

  const selectedTickets = orderItemData.map((item) => item.ticket);

  const hasOpenPriceTicket = selectedTickets.find((t) => t.has_open_price);

  const { control, handleSubmit, formState } = form;

  const handleCouponChange = (coupon: CouponDTO | null) => {
    setAppliedCoupon(coupon);
  };

  const handleAmountChange = (amount: number | undefined) => {
    setOpenPriceAmount(amount);
  };

  const handleCalculatedTotalToPay = (total: number) => {
    setTotalToPay(total);
  };

  const getPayButtonLabel = () => {
    if (isBackoffice || totalToPay === 0) {
      return 'Confirmar';
    }
    if (paymentMethod === 'online') {
      return 'Pagar';
    }
    if (paymentMethod === 'manual') {
      return 'Solicitar entradas';
    }
    return 'Confirmar';
  };

  useEffect(() => {
    setShowPaymentOptions(
      !!(
        event.allow_manual_payments
        && event.allow_online_payments
        && agent?.phone_number
        && !isBackoffice
        && totalToPay > 0
      ),
    );
  }, [event, agent, isBackoffice, totalToPay]);

  const getOrderData = useCallback(
    () => ({
      email: form.getValues('email'),
      items: orderItemData.map((item) => ({
        name: item.name,
        last_name: item.last_name,
        person_id: item.person_id,
        ticket_id: item.ticket.id,
        event_seat_id: item.seat_id,
      })),
      coupon_id: appliedCoupon?.id,
      open_price_amount: openPriceAmount,
      poll_responses: pollData,
      is_manual: paymentMethod === 'manual' || isBackoffice || totalToPay === 0,
      skip_poll: skipPoll,
      referral_code: refCode,
      from_backoffice: isBackoffice,
      is_seller_order: isBackoffice && role === 'SELLER',
      reservation_code: reservation?.code,
    }),
    [
      form,
      orderItemData,
      appliedCoupon,
      openPriceAmount,
      pollData,
      paymentMethod,
      isBackoffice,
      totalToPay,
      skipPoll,
      refCode,
      reservation,
    ],
  );

  useEffect(() => {
    setOrderData(getOrderData());
  }, [getOrderData]);

  const handlePay = () => {
    setClickedPay(true);
    // revisar
    const action = createOrder({
      order: getOrderData(),
      businessId: event.business_id,
      eventId: event.id,
    });

    action
      .unwrap()
      .then((res) => {
        dispatch(clearReservation());
        const od = res.data;
        if (od) {
          if (isBackoffice) {
            toast.success('Orden creada', {
              duration: 5000,
              position: 'bottom-center',
            });
            return navigate('../../');
          }
          if (totalToPay === 0) {
            return navigate(`/order-success?id=${od.custom_id}`);
          }
          const checkoutLink = od.payments?.[0]?.checkout_link;
          if (!od.is_manual) {
            if (checkoutLink) {
              window.location.href = checkoutLink;
            }
          } else {
            useSendWhatsapp(
              agent?.phone_number || event.phone,
              generateWhatsappMessage(event, od, orderItemData, totalToPay),
            );
            return navigate(`/order-success?id=${od.custom_id}`);
          }
        } else {
          throw new Error('no order data');
        }
        return '';
      })
      .catch(async (res) => {
        console.error(res);
        setClickedPay(false);
        if (res?.data?.error === 'no tickets available to fulfill order') {
          toast.error(
            'Lo sentimos, las entradas que habías solicitado se agotaron.',
            {
              duration: 10000,
              position: 'bottom-center',
            },
          );
          return navigate('../tickets');
        }
        toast.error(
          'Se produjo un error al crear la orden. Intenta nuevamente',
          {
            duration: 5000,
            position: 'bottom-center',
          },
        );
        return '';
      });
  };

  return (
    <form onSubmit={handleSubmit(handlePay)}>
      <div className="flex px-1 py-[16px] flex-col gap-[24px] items-center self-stretch">
        <div className="bg-white rounded-lg flex p-3 flex-col items-center self-stretch shadow-sm">
          <div className="flex flex-col gap-[20px] items-start self-stretch">
            <h2 className="text-lg font-semibold">Contacto</h2>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  label="Correo electrónico"
                  id="email"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                  }
                  errMsg={formState.errors.email?.message}
                  labelAbove
                />
              )}
            />
            <Controller
              name="emailConfirm"
              control={control}
              render={({ field }) => (
                <Input
                  label="Confirmar correo electrónico"
                  id="emailConfirm"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                  }
                  autoComplete={
                    config.production && !isBackoffice
                      ? 'idontwantthistoeverautocompleteokok??'
                      : 'email'
                  }
                  onPaste={(e) => (config.production && !isBackoffice ? e.preventDefault() : e)
                  }
                  errMsg={formState.errors.emailConfirm?.message}
                  labelAbove
                />
              )}
            />
          </div>
        </div>
        {orderData && (
          <OrderSummary
            order={orderData}
            event={event}
            onCalculatedTotalToPay={handleCalculatedTotalToPay}
            onLoadingTotals={setTotalsLoading}
            hasOpenPrice={!!hasOpenPriceTicket}
            items={orderItemData}
            customized={customized}
          />
        )}
        {totalsLoading && <OrderTotalSkeleton />}
        <div
          className="flex flex-col gap-[20px] items-start self-stretch"
          hidden={totalsLoading}
        >
          {((totalToPay > 0 && !hasOpenPriceTicket) || appliedCoupon) && (
            <div className="w-full">
              <GotCoupon
                loadedCode={couponCode}
                onCouponChange={handleCouponChange}
              />
            </div>
          )}
          {hasOpenPriceTicket && (
            <OpenPriceCard
              ticket={selectedTickets[0]}
              onAmountChange={handleAmountChange}
            />
          )}
          {showPaymentOptions && (
            <div className="bg-white rounded-lg flex flex-col gap-[32px] p-3 self-stretch shadow-sm">
              <h2 className="text-lg font-semibold">Método de pago</h2>
              <RadioButton
                name="paymentMethod"
                value="online"
                checked={paymentMethod === 'online'}
                description="Realizarás el pago online a través de MercadoPago y recibirás las entradas en tu correo."
                label="Mercado Pago"
                disabled={clickedPay}
                onChange={() => setPaymentMethod('online')}
              />
              <RadioButton
                name="paymentMethod"
                value="manual"
                description="Enviarás un mensaje por WhatsApp a una operadora que te indicará como realizar el pago."
                checked={paymentMethod === 'manual'}
                label="Transferencia"
                disabled={clickedPay}
                onChange={() => setPaymentMethod('manual')}
              />
            </div>
          )}
          {!isBackoffice && (
            <div className="w-full text-center text-sm text-gray-600">
              Al continuar, aceptas nuestros{' '}
              <button
                type="button"
              className="text-primary underline"
              onClick={() => setTermsModalOpen(true)}
            >
              términos y condiciones
            </button>
          </div>)}
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-8 justify-center w-full">
          <Button variant="secondary" onClick={() => navigate('../entry-data')}>
            Volver
          </Button>
          <Button
            variant="primary"
            loading={results.isLoading || totalsLoading}
            disabled={hasOpenPriceTicket && !openPriceAmount}
            type="submit"
          >
            {getPayButtonLabel()}
          </Button>
        </div>
      </div>

      <TermsAndConditions
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        type={TermsAndServicesType.BUYER}
      />
    </form>
  );
};

export default CheckoutPage;
