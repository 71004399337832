import { useEffect, useState } from 'react';
import { PiWarningCircleLight } from 'react-icons/pi';
import FilterTable, { BaseData } from './Table/Table';
import { useDeleteOrderMutation, useUpdateOrderStatusMutation } from '../store';
import IconInfoCard from './atoms/IconInfoCard';
import Button from './atoms/Button';
import CustomModal from './atoms/CustomModal';
import { formatCurrency } from '../utils/shared';
import { OrderDTO } from '../models/order';

// Remove BaseData from the import and create our own interface
interface OrderItemBase {
  [key: string]: string | number | boolean;
}

interface OrderItem extends OrderItemBase {
  name: string;
  person_id: string;
  id: number;
  amount: number;
  order_status: string;
  item_id: number;
  first_name: string;
  last_name: string;
  email: string;
  order_id: number;
  created_at: string;
  order_display_id: string;
  ticket_name: string;
  order_custom_id: string;
  is_manual: boolean;
  ticket_is_extra: boolean;
}

interface TableRow extends BaseData {
  displayId: string;
  name: string;
  amount: string;
}

interface Props {
  orders: OrderDTO[];
  eventId: number;
  businessId: number;
}

const PendingOrdersTable: React.FC<Props> = ({ orders, eventId, businessId }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [updateOrderStatus, resultsUpdate] = useUpdateOrderStatusMutation();
  const [deleteOrder, resultsDelete] = useDeleteOrderMutation();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [actionBar, setActionBar] = useState(<div></div>);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

  useEffect(() => {
    setOrderItems(
      orders.filter((o) => o.items && o.items.length > 0)
        .map((o) => ({
          name: `${o.items[0].name} ${o.items[0].last_name}`,
          person_id: o.items[0].person_id,
          id: o.id,
          amount: o.total_amount,
          order_status: o.status,
          item_id: o.items[0].id,
          first_name: o.items[0].name,
          last_name: o.items[0].last_name,
          email: o.email,
          order_id: o.id,
          is_manual: o.is_manual,
          created_at: o.created_at,
          order_display_id: o.display_id,
          ticket_name: o.items[0].name,
          ticket_is_extra: false,
          order_custom_id: o.custom_id,
        })),
    );
  }, [orders]);

  const tableRows: TableRow[] = orderItems
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .map((item) => ({
      id: item.id,
      displayId: item.order_display_id,
      name: item.name,
      amount: formatCurrency(item.amount),
      email: item.email,
    }));

  const handleSelectItem = (selectedIds: readonly number[]) => {
    setSelectedItems([...selectedIds]);
  };

  const handleConfirmRejectOrders = () => {
    selectedItems.map((id) => {
      deleteOrder({
        businessId: +businessId,
        eventId: +eventId,
        orderId: id,
      });
      return setSelectedItems(selectedItems.filter((p) => p !== id));
    });
    setShowModal(false);
  };

  const handleRejectOrders = () => {
    setModalBody(
      <IconInfoCard
        iconColor="text-red-600"
        icon={PiWarningCircleLight}
        title="¿Deseas rechazar la solicitud?"
        info="Una vez rechazada desaparecerá de la lista de solicitudes."
      />,
    );
    setActionBar(
      <div className="w-full">
        <Button
          variant="danger"
          onClick={handleConfirmRejectOrders}
          className="text-center w-full mt-2"
        >
          Rechazar
        </Button>
      </div>,
    );
    setShowModal(true);
  };

  const handleConfirmAcceptOrders = () => {
    console.log({ selectedItems });
    selectedItems.map(async (id) => {
      await updateOrderStatus({
        businessId,
        eventId: +eventId,
        orderId: id,
        newStatus: 'paid',
      });
      return setSelectedItems(selectedItems.filter((p) => p !== id));
    });
    setShowModal(false);
  };

  const handleAcceptOrders = () => {
    setModalBody(
      <div className="flex flex-col gap-2 p-1">
        <h1>Aceptar solicitud</h1>
        <p className="max-w-sm">Esta acción confirmará la/las solicitud/es de pago. Una vez confirmada una solicitud, podrás gestionarla desde la pestaña “Tickets confirmados”</p>
      </div>,

    );
    setActionBar(
      <div className="flex flex-col px-1 pt-7 pb-1">
        <div className="w-full flex gap-4">
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmAcceptOrders}
          >
            Aceptar
          </Button>
        </div>
      </div>,

    );
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const headCells = [
    {
      id: 'displayId',
      label: 'ID',
    },
    {
      id: 'name',
      label: 'Apellido y nombre',
    },
    {
      id: 'amount',
      label: 'Monto',
    },
  ];

  return (
    <div className="flex flex-col gap-8">
      <FilterTable
        rows={tableRows}
        headCells={headCells}
        pagination={{ enabled: true, pageSize: 10 }}
        searchEnabled={true}
        allowSelect={true}
        onSelectionChange={handleSelectItem}
      />
      <div className="flex justify-center align-items-center gap-4 w-full">
        <Button

          loading={resultsDelete.isLoading}
          disabled={selectedItems.length === 0}
          variant="secondary"
          onClick={handleRejectOrders}
        >
          Rechazar
        </Button>
        <Button
          loading={resultsUpdate.isLoading}
          disabled={selectedItems.length === 0}
          variant="primary"
          onClick={handleAcceptOrders}
          className="mr-4"
        >
          Aceptar
        </Button>
      </div>
      <CustomModal
        actionBar={actionBar}
        onRequestClose={handleClose}
        isOpen={showModal}
        hideCancelButton={true}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default PendingOrdersTable;
