import { useState } from 'react';
import toast from 'react-hot-toast';
import MenuDropdown from './atoms/MenuDropdown';
import CustomModal from './atoms/CustomModal';
import { useDeleteReferralMutation } from '../store';
import Button from './atoms/Button';
import { ReferralDTO } from '../types/Referral';
import CreateEditReferral from './CreateEditReferral';
import config from '../config/config';
import useGetSelectedBusiness from '../hooks/useGetSelectedBusiness';
import FilterTable, { BaseData } from './Table/Table';

interface Props {
  referrals: ReferralDTO[];
  eventId: number;
  businessId: number;
}

interface TableReferrals extends BaseData {
  id: number;
  code: string;
  name: string;
  tickets_sold: number;
  active: JSX.Element;
}

const ReferralsTable: React.FC<Props> = ({ referrals, eventId, businessId }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [deleteReferral] = useDeleteReferralMutation();
  const { business } = useGetSelectedBusiness();

  const handleConfirmDeleteReferral = (referral: ReferralDTO) => {
    deleteReferral({
      businessId,
      eventId: +eventId,
      referralId: referral.id,
    });
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteReferral = (referral: ReferralDTO) => {
    setModalBody(
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-medium">Eliminar referido</h1>
          <p >
            ¿Deseas eliminar este referido?
          </p>
          <p className="mt-2">
            Una vez eliminado desaparecerá de la lista y no se podrá revertir.
          </p>
        </div>
        <div className="flex flex-row gap-2">
          <Button

            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => handleConfirmDeleteReferral(referral)}
          >
            Eliminar
          </Button>
        </div>
      </div>,

    );
    setShowModal(true);
  };

  const handleEditReferral = (referral: ReferralDTO) => {
    setModalBody(
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-medium">Editar referido</h1>
          <p className="text-gray-400 text-sm">
            Podrás realizar las ediciones que necesites en este referido
          </p>
        </div>
        <CreateEditReferral referral={referral} onSuccess={() => {
          handleClose();
          toast.success('Referido editado correctamente');
        }} onCancel={handleClose} />
      </div>,

    );
    setShowModal(true);
  };

  const handleCopyReferralLink = (referral: ReferralDTO) => {
    const domain = business?.nickname ? `${business.nickname}.${config.domain}` : config.baseDomain;
    const link = business?.nickname ? `${domain}?ref=${referral.code}` : `${domain}/events/${eventId}?ref=${referral.code}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success('Link copiado al portapapeles');
      })
      .catch((err) => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };

  const dropDownOptions = [
    {
      label: 'Copiar link',
      onClick: handleCopyReferralLink,
    },
    {
      label: 'Editar',
      onClick: handleEditReferral,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteReferral,
    },
  ];

  const tableConfig = [
    {
      label: 'Nombre',
      id: 'name',
    },
    {
      label: 'Estado',
      id: 'active',
    },
    {
      label: 'Código',
      id: 'code',
    },
    {
      label: 'Veces usado',
      id: 'tickets_sold',
    },
  ];

  const actions = (row: TableReferrals) => (
    <div className='w-fit mx-auto'>
      <MenuDropdown<ReferralDTO>
        options={dropDownOptions}
        data={referrals.find((r) => r.id === row.id)!}
      />
    </div>
  );

  const rows: TableReferrals[] = referrals.map((referral) => ({
    id: referral.id,
    code: referral.code,
    name: referral.name,
    tickets_sold: referral.tickets_sold,
    active: referral.active ? (
      <div className='bg-primary-base w-fit p-1 rounded-lg text-white text-medium'>Habilitado</div>
    ) : (
        <div className='bg-warning-light w-fit p-1 rounded-lg text-gray-900 text-medium'>No habilitado</div>
    ),
  }));

  return (
    <div>
      <FilterTable
        rows={rows}
        headCells={tableConfig}
        pagination={{
          enabled: true,
          pageSize: 10,
        }}
        searchEnabled={true}
        actions={actions}
      />
      <CustomModal
        onRequestClose={handleClose}
        isOpen={showModal}
        hideCancelButton={true}
      >
        {modalBody}
      </CustomModal>

    </div>
  );
};

export default ReferralsTable;
