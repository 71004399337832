import React, { useState } from 'react';
import config from '../config/config';
import TermsAndConditions from './TermsAndConditions';
import TermsAndServicesType from '../models/terms';

const Footer: React.FC = () => {
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const handleClickSellAvantti = () => {
    window.open('https://organizadores.avantti.io/', '_blank');
  };

  const handleClickHelp = () => {
    window.open('https://wa.me/message/GV2TPBX7VGT5A1', '_blank');
  };

  return (
    <>
      <div className="flex flex-col py-12 px-4 flex-col justify-between items-center gap-8 bg-background-black text-white">
        <div className='flex flex-col md:flex-row items-center px-4 gap-10 self-stretch justify-between'>
          <div className="flex flex-row align-center">
            <img
              src="/avantti-footer.svg"
              className="inline-block mr-3"
              alt="Avantti logo"
            />
          </div>
          <div className="flex flex-col md:flex-row items-center gap-3">
            <a className="cursor-pointer" onClick={() => handleClickSellAvantti()}>
              Quiero vender con avantti
            </a>
            <div><a className="cursor-pointer" onClick={() => handleClickHelp()}>Ayuda</a></div>
            <div>
              <a className="cursor-pointer" onClick={() => setTermsModalOpen(true)}>
                Términos y condiciones
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center gap-6 self-stretch">
            <div>
              <a href="https://www.instagram.com/avantti.app/"><img src="/instagram-logo.svg" alt="Instagram" /></a>
            </div>
            <div>
              <a href="https://www.facebook.com/people/Avantti/61561762903235/"><img src="/facebook-icon.svg" alt="Facebook" /></a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/avantti-io"><img src="/linkedin-logo.svg" alt="Linkedin" /></a>
            </div>
            <div>
              <a href="https://organizadores.avantti.io/"><img src="/web-icon.svg" alt="Website" /></a>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center text-xs text-gray-400 mt-3 text-secondary font-light">
          <div>©Copyright @ 2025 avantti.io</div>
          <div>All rights reserved.</div>
          <div className="text-xs absolute left-9">v{config.version}</div>
        </div>
      </div>

      <TermsAndConditions
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        type={TermsAndServicesType.BUYER}
      />
    </>
  );
};

export default Footer;
