import { BusinessDTO } from './business';
import { UserPermissions } from './user';

export type InvitationStatus = 'accepted' | 'rejected' | 'pending';

export enum EventUserPermissions {
  SELLER = 'SELLER',
}

export interface CreateInvitationEventDTO {
  event_id: number;
  permissions: EventUserPermissions;
  seller_comission_rate: number;
}

export default interface InvitationDTO {
  id: number;
  email: string;
  business_id: number;
  token: string;
  status: InvitationStatus;
  permissions: UserPermissions;
  business: BusinessDTO;
}

export interface CreateInvitationDTO {
  email: string;
  permissions: UserPermissions;
  seller_commission_rate?: number;
  invitation_events?: CreateInvitationEventDTO[];
}
