import Button from './atoms/Button';
import { formatCurrency } from '../utils/shared';
import EventDTO, { EventPublicStatus } from '../models/event';

interface TicketProps {
  price: number;
  onClick: () => void;
  event: EventDTO;
}

const Ticket: React.FC<TicketProps> = ({ price, onClick, event }) => {
  const getPublicStatusText = (eventPublicStatus: EventPublicStatus) => {
    switch (eventPublicStatus) {
      case EventPublicStatus.PendingApproval:
        return 'El evento no ha sido aprobado aún';
      case EventPublicStatus.NoTicketsAvailable:
        return 'No hay entradas disponibles';
      case EventPublicStatus.Expired:
        return 'El evento ha finalizado';
      default:
        return '';
    }
  };
  return (
  <div className="flex items-center w-full">
    <div className="flex w-[10px] h-[132px] flex-col justify-center flex-shrink-0">
      <img src="/ticket-frame.svg" alt="ticket" className="w-[10px] h-[116px]" />
    </div>
    <div className="bg-primary-base w-[4px] h-[116px]"></div>
    <div className="flex items-center gap-4 justify-center flex-col w-5/6 h-[132px] shrink-0 rounded-l-lg bg-white shadow-sm">
      {(event.public_status !== EventPublicStatus.Ready) ? (
        <div className="text-lg font-semibold justify-center text-center mx-2">{getPublicStatusText(event.public_status)}</div>
      ) : (
        <>
          <div className="text-lg font-semibold justify-center text-center mx-2">Entradas desde {formatCurrency(price, 'no-decimals')}</div>
          <div>
            <Button variant="primary" onClick={onClick}><div className="whitespace-nowrap">Comprar entradas</div></Button>
          </div>
        </>
      )}
    </div>
    <div className="w-1/6 h-[132px] bg-background-black rounded-r-lg bg-[#222] shadow-sm"></div>
    <div className="bg-primary-base w-[4px] h-[116px]"></div>
    <div className="flex w-[10px] h-[132px] flex-col rotate-180 justify-center flex-shrink-0">
      <img src="/ticket-frame.svg" alt="ticket" className="w-[10px] h-[116px]" />
    </div>
  </div>
  );
};

export default Ticket;
