import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetEventByIdQuery } from '../store';
import { parseFriendlyDate } from '../utils/dates';
import EventPageSkeleton from '../components/EventPageSkeleton';
import { setSelectedEvent } from '../store/slices/events';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import Carrousel from '../components/atoms/Carrousel/Carrousel';
import LottieAnimation from '../components/atoms/LottieAnimation';
import Ticket from '../components/Ticket';
import { TicketPublicSaleStatus } from '../models/ticket';
// import CarrouselCards from '../components/atoms/CarrouselCards/CarrouselCards';

interface Props {
  loadedEventId?: number;
}

const EventPage: React.FC<Props> = ({ loadedEventId }) => {
  const { event_id: eventId } = useParams();
  const eventIdToUse = loadedEventId || +(eventId || 0);
  const { data: event, isLoading: eventLoading } = useGetEventByIdQuery(
    { eventId: eventIdToUse },
    { skip: !eventIdToUse },
  );
  const business = event?.business;
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();

  useEffect(() => {
    if (event) {
      dispatch(setSelectedEvent(event));
    }
  }, [business, event]);

  const tickets = event?.tickets?.filter((t) => t.public_sale_status === TicketPublicSaleStatus.Available && t.public_sale_available);

  if (eventLoading) {
    return <EventPageSkeleton />;
  }

  if (!business || !event || !tickets) {
    navigate('..');
    console.error('missing required data', { business, event, tickets });
    return <div></div>;
  }

  const importantInfo = event.terms
    ? event.terms.split('\n').map((str, index, array) => (
      <div key={index} className="mb-1">
        {str}
        {index !== array.length - 1 && <br />}
      </div>
    ))
    : null;

  const eventDescription = event.description
    .split('\n')
    .map((str, index, array) => (
      <div key={index} className="mb-1">
        {str}
        {index !== array.length - 1 && <br />}
      </div>
    ));

  if (!event || !business) {
    throw new Error('no event, business');
  }

  const handleClickBuyTickets = () => {
    navigate(`orders/new/${event.has_numerated_seats ? 'seats' : 'tickets'}`);
  };

  return (
    <div className="grid grid-cols-1 w-full my-6">
      <div className="grid grid-cols-1 gap-8 md:gap-12">
        <div className="flex items-center justify-center mb-3">
          <Carrousel
            images={[{ url: event.banner_image, eventId: event.id }]}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col">
            <h1 className="text-[24px] md:text-[48px] font-semibold">{event.name}</h1>
            <h2 className="text-lg md:text-[20px]">Organiza: {business.name}</h2>
          </div>
          <div>
            <Ticket price={Math.min(...tickets.map((ticket) => ticket.price))} onClick={handleClickBuyTickets} event={event} />
          </div>
          <div className='flex flex-col gap-6'>
            <div className="flex flex-col gap-2">
              <h1 className="text-lg md:text-[20px] font-semibold">Sobre el evento</h1>
              <div>{eventDescription}</div>
            </div>
            {importantInfo && (
              <div className="flex flex-col gap-2">
                <h1 className="text-lg md:text-[20px] font-semibold">Más información</h1>
                <div>{importantInfo}</div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6">
            <div className="w-full">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg md:text-[20px] font-semibold">Público</h1>
                  <p>
                    {event.min_age
                      ? `Mayores de ${event.min_age} años`
                      : 'Apto para todo público'}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg md:text-[20px] font-semibold">Fecha y hora</h1>
                  <p>
                    {parseFriendlyDate(event.date, true)} - {event.time}hs
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-lg md:text-[20px] font-semibold">Ubicación</h1>
                  <p>{event.place}</p>
                  <p>{event.address}</p>
                </div>
                {event.maps_link && (
                  <iframe
                    src={event.maps_link}
                    className="w-full mx-auto h-36 md:h-64 rounded-lg"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
        <LottieAnimation height='30px' path="/cards.json" className="w-full h-10" slice />
        {/* <div className="flex flex-row items-center gap-4">
          <span className="text-xl-semibold lg:display-md-semibold text-grey-700">
            Te puede interesar
          </span>
          <img src="/flower.svg" />
          <img src="/yellow-square.svg" />
        </div>
        <div><CarrouselCards cards={cardsTest} /></div> */}
      </div>
    </div>
  );
};

export default EventPage;
