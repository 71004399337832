/* eslint-disable no-nested-ternary */
import { Fragment, useEffect, useState } from 'react';
import { FiUserCheck, FiUsers } from 'react-icons/fi';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PendingOrdersTable from '../components/PendingOrdersTable';
import {
  RootState,
  useGetEventOrdersQuery,
  useGetOneSellerSummaryQuery,
  useGetSellerOrdersQuery,
} from '../store';
import { OrderDTO } from '../models/order';
import OrderItem from '../types/OrderItem';
import config from '../config/config';
import IconInfoCard from '../components/atoms/IconInfoCard';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import ApprovedOrdersTable from '../components/ApprovedOrdersTable';
import useGetParams from '../hooks/useGetParams';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import mapOrderToDisplayItems from '../utils/mapOrderToDisplayItems';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

import SellerSummary from '../components/SellerSummary';
import LoadingLottie from '../components/atoms/LoadingLottie';
import ReferralSummary from '../components/ReferralSummary';
import AdminSectionTitle from '../components/AdminSectionTitle';
import HorizontalTabs from '../components/atoms/HorizontalTabs';
import { EventPublicStatus } from '../models/event';

const filterNotActive = (orders: OrderDTO[]): OrderDTO[] => orders.filter((o) => o.status === 'pending' && o.is_manual);

const parseApprovedItems = (orders: OrderDTO[]): OrderItem[] => orders
  .filter((o) => o.status === 'paid' && o.items && o.items.length > 0)
  .flatMap((order) => order.items
    .filter((item) => !item.ticket?.is_extra)
    .map((item) => mapOrderToDisplayItems(order, item)));

const parseExtraItems = (orders: OrderDTO[]): OrderItem[] => orders
  .filter((o) => o.status === 'paid' && o.items && o.items.length > 0)
  .flatMap((order) => order.items
    .filter((item) => item.ticket?.is_extra)
    .map((item) => mapOrderToDisplayItems(order, item)));

const EventOrders: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const { event, eventLoading } = useGetSelectedEvent();
  const { eventId, businessId } = useGetParams();
  const [pendingOrders, setPendingOrders] = useState<OrderDTO[]>([]);
  const [activeOrders, setActiveOrders] = useState<OrderItem[]>([]);
  const [extraOrders, setExtraOrders] = useState<OrderItem[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const role = useGetUserRole();
  const [isFetching, setIsFetching] = useState(false);
  const [isSellerSalesEnabled, setIsSellerSalesEnabled] = useState(false);

  useEffect(() => {
    if (event) {
      setIsSellerSalesEnabled(
        event.seller_sales_enabled && event.public_status === EventPublicStatus.Ready,
      );
    }
  }, [event]);

  const { token, user: userData } = useSelector(
    (state: RootState) => state.auth,
  );

  const { data: sellerSummary, isFetching: isSellerSummaryLoading } = useGetOneSellerSummaryQuery(
    {
      businessId,
      eventId,
      sellerId: userData.id,
    },
    { skip: role !== 'SELLER' },
  );

  const { data: eventOrders, isFetching: isFetchingEventOrders } = useGetEventOrdersQuery(
    {
      businessId: event?.business_id || 0,
      eventId: +eventId,
    },
    { skip: !event || role === 'SELLER' },
  );

  const { data: sellerOrders, isFetching: isFetchingSellerOrders } = useGetSellerOrdersQuery(
    {
      businessId: event?.business_id || 0,
      eventId: +eventId,
    },
    { skip: !event || role !== 'SELLER' },
  );

  useEffect(() => {
    setIsFetching(isFetchingEventOrders || isFetchingSellerOrders);
  }, [isFetchingEventOrders, isFetchingSellerOrders]);

  useEffect(() => {
    const orders = role === 'SELLER' ? sellerOrders : eventOrders;
    if (orders?.data) {
      setPendingOrders(filterNotActive(orders.data));
      setActiveOrders(parseApprovedItems(orders.data));
      setExtraOrders(parseExtraItems(orders.data));
    }
  }, [eventOrders, sellerOrders]);

  const handleDownloadExport = () => {
    setIsDownloading(true);
    axios
      .get(
        `${config.baseURL}/v1/businesses/${event?.business_id}/events/${eventId}/orders?xlsx=true`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lista.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsDownloading(false));
  };

  const handleAddOrder = () => navigate('new/tickets');

  return (
    <div className="flex flex-col gap-8 w-full mt-8">
      <AdminSectionTitle
        title="Eventos"
        subtitle={`${event?.name} - Asistentes`}
        mainButtons={[
          {
            label: isSellerSalesEnabled
              ? 'Vender manualmente'
              : 'Venta no habilitada',
            onClick: handleAddOrder,
            variant: 'secondary',
            image: '/coins-hand.svg',
            disabled: !isSellerSalesEnabled,
          },
        ]}
      />
      {(role === 'SELLER' && !isSellerSalesEnabled) && (
        <div className="flex items-center justify-center">
          <span className="text-red-500">La venta no está habilitada</span>
        </div>
      )}
      {event?.allow_manual_payments && (
        <HorizontalTabs
          tabs={[
            {
              label: 'Tickets confirmados',
              onClick: () => setShowPending(false),
              selected: !showPending,
            },
            {
              label: 'Solicitudes pendientes',
              onClick: () => setShowPending(true),
              selected: showPending,
            },
          ]}
        />
      )}

      {pendingOrders && showPending && (
        <div>
          {isFetching || eventLoading ? (
            <OrdersTableSkeleton />
          ) : pendingOrders.length > 0 ? (
            <Fragment>
              <PendingOrdersTable
                orders={pendingOrders}
                eventId={+eventId}
                businessId={+businessId}
              />
            </Fragment>
          ) : (
            <div className="card">
              <IconInfoCard
                title="Sin solicitudes pendientes"
                info="Aquí te aparecerán las solicitudes pendientes de clientes que necesitan entradas"
                icon={FiUserCheck}
                iconColor="text-indigo-600"
              />
            </div>
          )}
        </div>
      )}

      {activeOrders && !showPending && (
        <div className="flex flex-col gap-4">
          {isFetching ? (
            <OrdersTableSkeleton />
          ) : !isFetching && activeOrders?.length > 0 ? (
            <ApprovedOrdersTable
              orderItems={activeOrders}
              buttonClick={role !== 'SELLER' ? handleDownloadExport : undefined}
              loading={isDownloading}
            />
          ) : (
            <div className="card">
              <IconInfoCard
                title="Aún no hay invitados confirmados"
                info="Aquí comenzarás a ver a los invitados una vez que sean aceptados"
                icon={FiUsers}
                iconColor="text-indigo-600"
              />
            </div>
          )}
        </div>
      )}

      {extraOrders && extraOrders.length > 0 && !showPending && (
        <div>
          <div className="flex justify-between py-2">
            <h1 className="text-xl">Extras vendidos</h1>
          </div>
          {isFetching ? (
            <OrdersTableSkeleton />
          ) : !isFetching && extraOrders?.length > 0 ? (
            <ApprovedOrdersTable orderItems={extraOrders} />
          ) : (
            <div className="card">
              <IconInfoCard
                title="Aún no hay extras vendidos"
                info="Aquí comenzarás a ver a los extras vendidos una vez que sean aceptados"
                icon={FiUsers}
                iconColor="text-indigo-600"
              />
            </div>
          )}
        </div>
      )}

      {!showPending && (
        <div className="flex flex-col gap-4">
          {role === 'SELLER' && (
            <div>
              {sellerSummary && <SellerSummary summary={sellerSummary} />}

              {isSellerSummaryLoading && <LoadingLottie />}
            </div>
          )}
          {role === 'SELLER' && sellerSummary?.referral && (
            <div>
              {sellerSummary && (
                <ReferralSummary referral={sellerSummary.referral} />
              )}
              {isSellerSummaryLoading && <LoadingLottie />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventOrders;
