import React, { useState, useRef } from 'react';
import { FaCloudArrowUp } from 'react-icons/fa6';
import Button from './atoms/Button';
import { useUploadImageMutation } from '../store/apis/common';
import { toastError } from '../utils/toasts';

interface PhotoUploadProps {
  className?: string;
  defaultPreview?: string;
  uploadButtonDisabled?: boolean;
  id?: string;
  onFileUploaded: (url: string) => void;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  className = '', defaultPreview, uploadButtonDisabled = false, id, onFileUploaded,
}) => {
  const [preview, setPreview] = useState<string>(defaultPreview || '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadImage, resultsUploadImage] = useUploadImageMutation();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toastError('El tamaño máximo permitido es 5MB');
        return;
      }

      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      resultsUploadImage.reset();
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleClickUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    console.log({ selectedFile });
    if (selectedFile) {
      try {
        const result = await uploadImage(selectedFile).unwrap();
        console.log({ result });
        onFileUploaded(result);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <div
      className={`relative cursor-pointer ${className} w-fit mx-auto max-w-[720px] bg-white rounded-lg`}
      onClick={handleClick}
    >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={handleFileSelect}
        id={id}
      />

      {preview ? (
        <div className="flex flex-col gap-2 border-2 border-dashed rounded-lg p-4">
          <span className="text-gray-500 text-sm font-light">Foto actual, pulse aquí para cambiarla</span>
          <img
            src={preview}
            alt="Preview"
            className="w-full h-full object-cover rounded-lg"
          />
          {((!uploadButtonDisabled && !defaultPreview) || selectedFile) && (
            <div className="flex justify-center whitespace-nowrap">
              <Button
                onClick={handleClickUpload}
                disabled={resultsUploadImage.isLoading || resultsUploadImage.isSuccess}
                loading={resultsUploadImage.isLoading}
              >
                <span className="flex items-center gap-2 text-lg">
                  <div className="text-2xl"><FaCloudArrowUp /></div>
                  {resultsUploadImage.isSuccess ? 'Imagen subida' : 'Subir'}
                </span>
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-full min-h-[200px] border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center">
          <div className="text-center">
            <p className="text-gray-500">Sube una foto aquí</p>
            <p className="text-sm text-gray-400">JPG, PNG, GIF hasta 5MB, idealmente de 1920x1080</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoUpload;
