import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { MdOutlineEventBusy } from 'react-icons/md';
import { CiSettings } from 'react-icons/ci';
import { RootState, useGetBusinessByIdQuery } from '../store';
import { setSelectedEvent } from '../store/slices/events';
import Button from '../components/atoms/Button';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import EventDTO from '../models/event';
import IconInfoCard from '../components/atoms/IconInfoCard';
import Switch from '../components/atoms/Switch';
import MenuDropdown from '../components/atoms/MenuDropdown';
import { BusinessDTO } from '../models/business';
import Input from '../components/atoms/Input';
import LandingEventCard from '../components/LandingEventCard';
import Select from '../components/atoms/Select/Select';
import AdminEventsSkeleton from '../components/AdminEventsSkeleton';

const AdminEvents: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const dispatch = useDispatch();
  const [authorizedEvents, setAuthorizedEvents] = useState<EventDTO[]>([]);
  const [showInactiveEvents, setShowInactiveEvents] = useState(false);
  const role = useGetUserRole();
  const userData = useSelector((state: RootState) => state.auth.user);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<string>('');

  const { business_id: businessId } = useParams();
  if (!businessId || !+businessId) {
    throw new Error('no business id');
  }

  const { data: business, isLoading } = useGetBusinessByIdQuery(+businessId);

  useEffect(() => {
    const sellerEventsId = userData.user_events?.map((e) => e.event_id);
    const baseEvents = business?.events
      ?.filter((e) => {
        const activeFilter = searchTerm || showInactiveEvents || e.active;
        const searchFilter = e.name.toLowerCase().includes(searchTerm.toLowerCase());
        return activeFilter && searchFilter;
      })
      ?.sort((a, b) => {
        if (sortBy === 'name') {
          return a.name.localeCompare(b.name);
        }
        // Default sort by date
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

    if (role === 'SELLER') {
      setAuthorizedEvents(
        baseEvents?.filter((e) => sellerEventsId.includes(e.id)) || [],
      );
    } else {
      setAuthorizedEvents(baseEvents || []);
    }
  }, [role, business, showInactiveEvents, searchTerm, sortBy]);

  const handleEventSelect = (eventId: number) => {
    const event = business?.events?.find((e) => e.id === eventId);
    if (event) {
      dispatch(setSelectedEvent(event));
    }
    navigate(`${eventId}`);
  };

  const dropDownOptions = [
    {
      label: 'Editar productora ',
      onClick: (b: BusinessDTO) => navigate(`../edit-business/${b.id}`),
      hidden: role !== 'ROOT',
    },
    {
      label: 'Editar usuarios',
      onClick: (b: BusinessDTO) => navigate(`../${b.id}/users`),
    },
    {
      label: 'Configurar Mercado Pago',
      onClick: (b: BusinessDTO) => navigate(`../${b.id}/mercadopago`),
      hidden: !business?.has_mp_split,
    },
  ];

  if (isLoading) {
    return <AdminEventsSkeleton />;
  }

  return (
    <div className="flex py-8 px-1 flex-col align-start gap-8 self-stretch">
      <div className="flex items-center gap-4 self-stretch">
        <div className="text-3xl text-gray-700 font-medium">Eventos</div>
        <img src={'/event-svg-2.svg'} alt="" />
        <img src={'/event-svg-1.svg'} alt="" />
      </div>

      {(userData.type === 'ROOT' || role === 'ADMIN') && (
        <div className="flex flex-col md:flex-row p-4 justify-between self-stretch gap-4 items-center text-center bg-white border border-gray-100 rounded-lg shadow-sm">
          <div className="text-lg">Creá un nuevo evento asociandolo con una productora existente</div>
          <div className="flex justify-center md:justify-between">
            <Button
              type="submit"
              variant="primary"
              className="mb-2"
              onClick={() => navigate('create')}
            >
              Crear nuevo evento
            </Button>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full md:flex-row gap-4">
        <div className="w-full bg-white h-12">
          <Input
            id="search-event"
            label="Buscar evento"
            placeholder="Buscar evento"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            isSearch
          />
        </div>
        <Select
          placeholder="Ordenar eventos"
          id="sort-events"
          label=""
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          options={[
            { value: 'date', label: 'Por fecha del evento' },
            { value: 'name', label: 'Por nombre' },
          ]}
        />
        <div className="w-full bg-white border border-gray-100 rounded-lg shadow-sm h-12 flex items-center px-3">
          <Switch
            label="Mostrar eventos inactivos"
            labelClassName="text-gray-900"
            onChange={(e) => setShowInactiveEvents(e.target.checked)}
          />
        </div>
      </div>

      {business && (role === 'ADMIN' || role === 'ROOT') && (
        <div className="flex justify-end">
          <MenuDropdown<BusinessDTO>
            options={dropDownOptions}
            data={business}
            icon={<CiSettings className="text-xl" />}
          />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {!isLoading && authorizedEvents?.map((event) => (
          <div className="w-full" key={event.id}>
            <LandingEventCard
              event={event}
              onSelect={handleEventSelect}
              textButton="Ver evento"
              adminEvents
            />
          </div>
        ))}
      </div>

      {!searchTerm && !isLoading && authorizedEvents.length === 0 && (
        <IconInfoCard
          title="No hay eventos disponibles"
          info="Todavía no tenés eventos asociados"
          icon={MdOutlineEventBusy}
          iconColor="text-indigo-600"
        />
      )}
      {searchTerm && !isLoading && authorizedEvents.length === 0 && (
        <div className="flex flex-col items-center">
          <div className="text-lg text-center">
            <h1 className="text-2xl font-medium">¡No encontramos el evento que buscas!</h1>
            <p>
              Probá con una combinación diferente de filtros.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdminEvents;
