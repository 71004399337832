import React, { useEffect, useState } from 'react';
import { BsPersonCheckFill } from 'react-icons/bs';
import Button from './atoms/Button';
import { DecodedItemData, DecodedSellerQRData, DecodedOrderQrData } from '../store/apis/orders';
import { parseFriendlyDateAndHour } from '../utils/dates';

type ScanResultType =
  | 'success_confirmed'
  | 'success_unconfirmed'
  | 'error'
  | 'used'
  | 'seller_qr_unconfirmed'
  | 'seller_qr_confirmed'
  | 'scan_period_expired'
  | 'order_qr';

interface ScanResultCardProps {
  type: ScanResultType;
  qrData: DecodedItemData | DecodedSellerQRData | DecodedOrderQrData | null;
  onRetry: () => void;
  onConfirm?: () => void;
  onConfirmSellerQR?: () => void;
  onConfirmOrderQR?: (itemIds: number[]) => void;
}

const QrResponse: React.FC<ScanResultCardProps> = ({
  type,
  qrData,
  onRetry,
  onConfirm,
  onConfirmSellerQR,
  onConfirmOrderQR,
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      event.preventDefault();

      if (event.key === 'Enter') {
        if (onConfirm && (type === 'success_unconfirmed' || type === 'seller_qr_unconfirmed')) {
          onConfirm();
        } else if (onConfirmSellerQR && type === 'seller_qr_unconfirmed') {
          onConfirmSellerQR();
        } else if (type === 'order_qr' && onConfirmOrderQR && selectedItems.length > 0) {
          onConfirmOrderQR(selectedItems);
        } else if (onRetry) {
          onRetry();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [type, onConfirm, onConfirmOrderQR, selectedItems]);

  const config = {
    success_confirmed: {
      bgColor: 'bg-green-100',
      iconBg: 'bg-green-200',
      iconColor: 'text-green-600',
      title: '¡Escaneo exitoso!',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      ),
    },
    seller_qr_confirmed: {
      bgColor: 'bg-blue-100',
      iconBg: 'bg-blue-200',
      iconColor: 'text-blue-600',
      title: 'Código RR.PP confirmado',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      ),
    },
    success_unconfirmed: {
      bgColor: 'bg-gray-50',
      iconBg: 'bg-gray-200',
      iconColor: 'text-gray-600',
      title: 'Código escaneado',
      icon: <BsPersonCheckFill className="w-20 h-20" />,
    },
    order_qr: {
      bgColor: 'bg-gray-50',
      iconBg: 'bg-gray-200',
      iconColor: 'text-gray-600',
      title: 'Código escaneado',
      icon: <BsPersonCheckFill className="w-20 h-20" />,
    },
    seller_qr_unconfirmed: {
      bgColor: 'bg-gray-50',
      iconBg: 'bg-gray-200',
      iconColor: 'text-gray-600',
      title: 'Código RR.PP escaneado',
      icon: <BsPersonCheckFill className="w-20 h-20" />,
    },
    error: {
      bgColor: 'bg-red-100',
      iconBg: 'bg-red-200',
      iconColor: 'text-red-600',
      title: '¡Escaneo erróneo!',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ),
    },
    used: {
      bgColor: 'bg-yellow-100',
      iconBg: 'bg-yellow-200',
      iconColor: 'text-yellow-600',
      title: '¡QR usado!',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      ),
    },
    scan_period_expired: {
      bgColor: 'bg-red-100',
      iconBg: 'bg-red-200',
      iconColor: 'text-red-600',
      title: '¡Código expirado!',
      icon: <BsPersonCheckFill className="w-20 h-20" />,
    },
  };

  const {
    bgColor, iconBg, iconColor, title, icon,
  } = config[type as keyof typeof config];

  const handleItemSelect = (itemId: number) => {
    setSelectedItems((prev) => (prev.includes(itemId)
      ? prev.filter((id) => id !== itemId)
      : [...prev, itemId]));
  };

  return (
    <div className={`${bgColor} min-h-full p-6 w-full overflow-y-auto flex flex-col`}>
      <div className="flex-1">
        <div className="flex justify-center mb-4">
          <div className={`${iconBg} mt-6 rounded-full p-8`}>
            <div className={iconColor}>{icon}</div>
          </div>
        </div>
        <h2 className={`text-center text-3xl font-medium ${type === 'order_qr' ? 'mb-6' : 'mb-6'}`}>{title}</h2>
        {type === 'success_unconfirmed'
          && qrData
          && 'order_status' in qrData && (
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-center text-xl text-gray-600 mb-2">
                {qrData?.ticket?.name}
              </p>
              <p className="text-center text-4xl font-medium text-gray-600">
                {`${qrData.name} ${qrData.last_name}`.toUpperCase()}
              </p>
              <p className="text-center text-4xl font-medium text-gray-600">
                {qrData.person_id}
              </p>
              <Button
                onClick={onConfirm}
                className={
                  'text-lg mt-20 h-14 w-full bg-green-200 py-2 px-4 border border-gray-200 rounded shadow hover:bg-opacity-50 transition duration-200'
                }
              >
                Aceptar
              </Button>
              <div className="flex items-center justify-center">
                <Button variant="tertiary" className="text-lg h-14" onClick={onRetry}>
                  Cancelar
                </Button>
              </div>
            </div>)}
        {type === 'seller_qr_unconfirmed'
          && qrData
          && 'user_event' in qrData && (
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-center text-xl text-gray-600 mb-2">
                Código Promocional RR.PP
              </p>
              <p className="text-center text-4xl font-medium text-gray-600">
                {`${qrData.user_event.user.username}`.toUpperCase()}
              </p>
              <Button
                onClick={onConfirm}
                className={
                  'text-lg mt-20 h-14 w-full bg-blue-200 py-2 px-4 border border-gray-200 rounded shadow hover:bg-opacity-50 transition duration-200'
                }
              >
                Aceptar
              </Button>
              <div className="flex items-center justify-center">
                <Button variant="tertiary" className="text-lg h-14" onClick={onRetry}>
                  Cancelar
                </Button>
              </div>
            </div>)}
        {type === 'seller_qr_confirmed' && qrData && 'user_event' in qrData && (
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-center text-xl text-gray-600 mb-2">
              Código Promocional RR.PP confirmado
            </p>
            <p className="text-center text-4xl font-medium text-gray-600">
              {`${qrData.user_event.user.username}`.toUpperCase()}
            </p>
          </div>
        )}
        {type === 'success_confirmed' && qrData && 'order_status' in qrData && (
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-center text-xl text-gray-600 mb-2">
              {qrData?.ticket?.name}
            </p>
            <p className="text-center text-4xl font-medium text-gray-600">
              {`${qrData.name} ${qrData.last_name}`.toUpperCase()}
            </p>
            <p className="text-center text-4xl font-medium text-gray-600">
              {qrData.person_id}
            </p>
          </div>
        )}
        {type === 'error' && (
          <p className="text-center text-2xl text-gray-600 mb-4 font-medium">
            No se pudo leer el código solicitado
          </p>
        )}
        {type === 'used' && qrData && 'used_at' in qrData && (
          <p className="flex flex-col items-center justify-center text-center text-2xl text-gray-600 mb-4 font-medium gap-2">
            <p>El código ya fue previamente escaneado:{' '}</p>
            <p>{parseFriendlyDateAndHour(qrData.used_at)}hs.</p>
          </p>
        )}
        {type === 'scan_period_expired' && qrData && 'ticket' in qrData && qrData.ticket?.scanning_ends_at && (
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="text-center text-2xl text-gray-600 font-medium">
              <p>El código es valido hasta: {parseFriendlyDateAndHour(qrData.ticket?.scanning_ends_at)}hs.</p>
              {qrData.ticket?.expired_message && (
                <p className="text-center text-2xl text-gray-600 font-medium mt-4">
                  {qrData.ticket?.expired_message}
                </p>
              )}
            </div>
            <Button
              onClick={onConfirm}
              className={
                'text-lg mt-20 h-14 w-full bg-green-200 py-2 px-4 border border-gray-200 rounded shadow hover:bg-opacity-50 transition duration-200'
              }
            >
              Aceptar
            </Button>
            <div className="flex items-center justify-center">
              <Button variant="tertiary" className="text-lg h-14" onClick={onRetry}>
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {type === 'order_qr' && qrData && 'items' in qrData && (
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-center text-xl text-gray-600 mb-4">
              Orden #{qrData.display_id}
            </p>
            <div className="space-y-4 mb-6 max-h-[40vh] overflow-y-auto">
              {qrData.items.map((item) => (
                <div
                  key={item.item_id}
                  className={`p-4 rounded-lg ${item.used_at ? 'bg-gray-100' : 'bg-white'}`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-lg font-medium">
                        {`${item.name} ${item.last_name}`.toUpperCase()}
                      </p>
                      <p className="text-gray-600">
                        {item.used_at
                          ? `Usado: ${parseFriendlyDateAndHour(item.used_at)}hs`
                          : 'No usado'}
                      </p>
                      {item.ticket && (
                        <p className="text-sm text-gray-500">{item.ticket.name}</p>
                      )}
                    </div>
                    {!item.used_at && (
                      <input
                        type="checkbox"
                        className="w-6 h-6 text-blue-600 rounded focus:ring-blue-500"
                        checked={selectedItems.includes(item.item_id)}
                        onChange={() => handleItemSelect(item.item_id)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {qrData.items.some((item) => !item.used_at) && (
              <div className="sticky bottom-0 pt-4 bg-gray-50">
                <Button
                  onClick={() => {
                    if (selectedItems.length > 0) {
                      onConfirmOrderQR?.(selectedItems);
                    }
                  }}
                  disabled={selectedItems.length === 0}
                  className={`
                    text-lg h-14 w-full py-2 px-4 border border-gray-200 rounded shadow transition duration-200
                    ${selectedItems.length > 0 ? 'bg-green-200 hover:bg-opacity-50' : 'bg-gray-100 cursor-not-allowed'}
                  `}
                >
                  Marcar seleccionados como ingresados ({selectedItems.length})
                </Button>
              </div>
            )}
            <div className="flex items-center justify-center mt-4">
              <Button variant="tertiary" className="text-lg h-14" onClick={onRetry}>
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {(!type.includes('success') && !type.includes('seller_qr') && !type.includes('order_qr') && type !== 'scan_period_expired') && (
          <div className="sticky bottom-0 bg-gray-50">
            <Button
              onClick={onRetry}
            >
              Reintentar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QrResponse;
