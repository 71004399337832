import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css';
import './Carrousel.css';

interface CarouselImage {
  url: string;
  eventId: number;
}

interface Props {
  images: CarouselImage[];
  isArrow?: boolean;
  onImageClick?: (eventId: number) => void;
}

const Carrousel: React.FC<Props> = ({ images, isArrow = false, onImageClick }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isArrow,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  // If there's only one image, render it directly without the carousel
  if (images.length === 1) {
    return (
      <div className='w-full relative h-carrousel'>
        <img
          src={images[0].url}
          alt="Single image"
          className='w-full h-full object-cover rounded-lg cursor-pointer'
          onClick={() => onImageClick?.(images[0].eventId)}
        />
      </div>
    );
  }

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className='w-full relative h-carrousel'>
            <img
              src={image.url}
              alt={`Slide ${index}`}
              className='w-full h-full object-cover border-radius cursor-pointer'
              onClick={() => onImageClick?.(image.eventId)}
            />
          </div>))}
      </Slider>
    </div>
  );
};

export default Carrousel;
