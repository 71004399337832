import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { UserWithBusinessAndEvent } from '../models/user';
import MenuDropdown from './atoms/MenuDropdown';
import {
  useDeleteUserEventMutation, useGetAllReferralsQuery, useLazyGetSellerEventQrQuery, useGetSellerSummaryQuery,
} from '../store';
import CustomModal from './atoms/CustomModal';
import LinkReferralToSeller from './LinkReferralToSeller';
import useGetUserRole from '../hooks/useGetUserRole';
import FilterTable, { BaseData } from './Table/Table';
import AddEditSellerToEvent from './AddEditSellerToEvent';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

interface Props {
  users: UserWithBusinessAndEvent[];
  businessId: number;
  eventId: number;
}

// Add this interface for the table data structure
interface TableSellerItem extends BaseData {
  id: number;
  username: string;
  commission: string;
}

const SellersTable: React.FC<Props> = ({ users, businessId, eventId }) => {
  const { event } = useGetSelectedEvent();
  const [deleteUserEvent, resultsDelete] = useDeleteUserEventMutation();
  const [getSellerEventQr] = useLazyGetSellerEventQrQuery();
  const { data: referrals } = useGetAllReferralsQuery({ businessId, eventId });
  const { data: sellerSummary, refetch: getSellerSummary } = useGetSellerSummaryQuery({ businessId, eventId });
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState<JSX.Element | null>(null);
  const [currentUser, setCurrentUser] = useState<UserWithBusinessAndEvent | null>(null);
  const role = useGetUserRole();

  useEffect(() => {
    getSellerSummary();
  }, [getSellerSummary]);

  console.log(sellerSummary);
  console.log(users);

  const handleDeleteUserEvent = async (user: UserWithBusinessAndEvent) => {
    toast.promise(
      deleteUserEvent({
        businessId,
        eventId: +eventId,
        userEventId: user.user_event.id,
      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Vendedor eliminado con éxito</b>,
        error: <b>Error al eliminar vendedor.</b>,
      },
    );
  };

  const handleDownloadQR = async (user: UserWithBusinessAndEvent) => {
    const res = await toast.promise(
      getSellerEventQr({
        businessId,
        eventId: +eventId,
        userEventId: user.user_event.id,
      }).unwrap(),
      {
        loading: 'Descargando QR...',
        success: <b>QR descargado con éxito</b>,
        error: <b>Error al descargar QR.</b>,
      },
    );

    if (res) {
      const a = document.createElement('a');
      a.href = `data:image/png;base64,${res.qr_image_b64}`;
      a.download = `qr_rrpp_${user.username}.png`;
      a.click();
    }
  };

  const handleLinkReferral = (user: UserWithBusinessAndEvent) => {
    setShowModal(true);
    setCurrentUser(user);
    setModalBody(<LinkReferralToSeller
      user={user}
      availableReferrals={referrals || []}
      onClose={() => setShowModal(false)}
    />);
  };

  const handleEditUser = (user: UserWithBusinessAndEvent) => {
    setCurrentUser(user);
    setShowModal(true);
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-xl font-medium'>Editar comisión</h1>
          <p className='text-gray-400 text-sm text-center'>
            Podrás editar la comisión de este vendedor
          </p>
        </div>
        <AddEditSellerToEvent
          businessId={businessId}
          eventId={eventId}
          users={users}
          onSuccess={() => setShowModal(false)}
          sellerToEdit={{
            userId: user.user_event.id,
            comissionRate: user.user_event.seller_comission_rate,
          }}
          onClose={() => setShowModal(false)}
        />
      </div>,
    );
  };

  const dropDownOptions = [
    {
      label: 'Asignar Referido',
      onClick: handleLinkReferral,
      hidden: role !== 'ROOT',
    },
    {
      label: 'Descargar QR RR.PP',
      hidden: !event?.has_seller_qr,
      onClick: handleDownloadQR,
    },
    {
      label: 'Editar',
      onClick: handleEditUser,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteUserEvent,
    },
  ];

  const headCells = [
    {
      id: 'username',
      label: 'Usuario',
    },
    {
      id: 'commission',
      label: 'Comisión',
    },
  ];

  const tableRows: TableSellerItem[] = users.map((user) => ({
    id: user.id,
    username: user.username,
    commission: `${user.user_event.seller_comission_rate * 100}%`,
  }));

  const actions = (row: TableSellerItem) => {
    const user = users.find((u) => u.id === row.id);
    if (!user) {
      throw new Error('User not found @ actions');
    }
    return (
      <div className='w-fit mx-auto'>
        <MenuDropdown<UserWithBusinessAndEvent>
          options={dropDownOptions}
          data={user}
          loading={resultsDelete.isLoading}
        />
      </div>
    );
  };

  return (
    <div>
      <FilterTable
        rows={tableRows}
        headCells={headCells}
        actions={actions}
        pagination={{
          pageSize: 10,
          enabled: true,
        }}
        searchEnabled={true}
      />
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        hideCancelButton
      >
        {currentUser && modalBody}
      </CustomModal>
    </div>

  );
};

export default SellersTable;
