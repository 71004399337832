import classNames from 'classnames';
import { FC, SelectHTMLAttributes, forwardRef } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Tooltip from '../Tooltip';
import './Select.css';

export interface Option {
  label: string;
  value: string | number;
}

interface CustomSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  options: Option[];
  errMsg?: string;
  tooltipText?: string;
  placeholder?: string;
}

const Select: FC<CustomSelectProps> = forwardRef<
HTMLSelectElement,
CustomSelectProps
>(({
  id, label, errMsg, tooltipText, options, placeholder = 'Seleccionar...', ...rest
}, ref) => {
  const commonClasses = 'p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-grey-300';
  const selectStyle = {
    backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' fill=\'none\'%3E%3Cpath d=\'M5 7.5L10 12.5L15 7.5\' stroke=\'%2351525C\' stroke-width=\'1.66667\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
  };
  const selectClasses = classNames(
    commonClasses,
    'w-full h-13 bg-white pr-8',
    'appearance-none',
    {
      'border-red-500': errMsg,
    },
  );

  return (
    <div className="form-control flex flex-col mb-2 w-full">
      <div className='flex items-center w-full'>
        <label htmlFor={id} className="text-left">
          {label}
        </label>
        {tooltipText && (
          <Tooltip content={tooltipText}>
            <AiOutlineQuestionCircle className='ml-1' />
          </Tooltip>
        )}
      </div>
      <div className="flex items-center">
        <select style={selectStyle} id={id} ref={ref} className={selectClasses} {...rest}>
          <option value="" hidden>{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value} className="options">
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <span className="text-red-500 white-text-shadow">{errMsg}</span>
    </div>
  );
});

export default Select;
