import { Fragment, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ColumnConfig } from '../../hooks/useHook';
import SearchInput from './SearchInput';

interface TableProps<T> {
  data: T[];
  config: ColumnConfig<T>[];
  keyFn: (data: T) => string | number;
  pagination: boolean;
  search: boolean;
  tableClassnames?: string;
  tableHeaderClassnames?: string;
  tableBodyClassnames?: string;
  tableRowClassnames?: string;
}

function LegacyTable<T>({
  data, config, keyFn, pagination, search,
}: TableProps<T>) {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = pagination ? 10 : 999999999; // Change as needed
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = data.filter((item) => JSON.stringify(item).toLowerCase().includes(searchQuery.toLowerCase()));

  const offset = currentPage * itemsPerPage;
  const pagedData = filteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchQuery]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    console.log({ selectedItem });
    setCurrentPage(selectedItem.selected);
  };

  const renderedRows = (rowData: T) => config.map((c, index) => (
      <td
        key={`${c.label}${index}`}
        className={`${c.label ? 'p-2 font-medium text-gray-900 text-sm' : ''}`}
      >
        {c.render(rowData)}
      </td>
  ));

  const renderedData = pagedData.map((d, i) => (
    <tr key={keyFn(d)} className={`${i !== (pagedData.length - 1) ? 'border-b' : ''}`}>
      {renderedRows(d)}
    </tr>
  ));

  const renderedHead = config.map((column, index) => {
    if (column.header) {
      return <Fragment key={index}>{column.header()}</Fragment>;
    }
    return (
      <th className="text-left p-2" key={index}>
        {column.label}
      </th>
    );
  });

  return (
    <div>
    {search && <SearchInput value={searchQuery} onChange={setSearchQuery} />}
      <table className="table-auto border-spacing-2 w-full">
        <thead className="bg-gray-100 text-gray-500 text-center text-xs">
          <tr>{renderedHead}</tr>
        </thead>
        <tbody className='bg-white'>{renderedData}</tbody>
      </table>
      {pagination && <div className="flex justify-center my-4 items-center">
        <ReactPaginate
          previousLabel="&larr;"
          nextLabel="&rarr;"
          // breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          onPageChange={handlePageClick}
          containerClassName={'flex justify-between'}
          pageClassName="hidden"
          activeClassName={'active'}
          previousLinkClassName="px-4 py-2 mx-1 text-lg text-gray-900 hover:bg-gray-300 hover:text-gray-700 text-sm rounded-full"
          nextLinkClassName="px-4 py-2 mx-1 text-lg text-gray-900 hover:bg-gray-300 hover:text-gray-700 text-sm rounded-full"
          disabledClassName="opacity-50 cursor-not-allowed"
        />
      </div>}
    </div>
  );
}

export default LegacyTable;
