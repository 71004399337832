import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EventDTO from '../models/event';
import { OrderItemData } from '../models/order';
import { OrderData, useGetOrderTotalsQuery } from '../store/apis/orders';
import { formatCurrency } from '../utils/shared';
import OrderTotalSkeleton from './OrderTotalSkeleton';

interface Props {
  items: OrderItemData[];
  customized: boolean;
  event: EventDTO;
  order: OrderData;
  onCalculatedTotalToPay: (total: number) => void;
  onLoadingTotals: (loading: boolean) => void;
  hasOpenPrice?: boolean;
}

const OrderSummary: React.FC<Props> = ({
  items, customized, event, order, onCalculatedTotalToPay, onLoadingTotals, hasOpenPrice,
}) => {
  const { data: orderTotals, isFetching } = useGetOrderTotalsQuery({
    businessId: event.business_id,
    eventId: event.id,
    order,
  }, { skip: hasOpenPrice && !order.open_price_amount });

  const location = useLocation();
  const isBackoffice = location.pathname.includes('/admin/');

  useEffect(() => {
    if (orderTotals) {
      onCalculatedTotalToPay(orderTotals.total_amount);
    }
  }, [orderTotals]);

  useEffect(() => {
    onLoadingTotals(isFetching);
  }, [isFetching]);

  return (
    <div className="flex flex-col bg-white rounded-lg shadow-sm w-full p-3 gap-[32px]">
      <h1 className="text-lg font-semibold">Resumen</h1>
      <div className="flex flex-col border rounded-lg">
        {items.map((item, index) => (
          <div className={'text-secondary text-gray-500 border-b p-[16px]'} key={index}>
            <div>
              {customized && <div className="flex flex-row justify-between">
                    <div className="font-medium">{item.name} {item.last_name}</div>
                    <div>
                      {!hasOpenPrice && <span>{formatCurrency(item.ticket.price)}</span>}
                    </div>
                  </div>}
                <div className="flex justify-between items-center">
                  <span>
                    {!item.ticket_extra && <span> {item.ticket.name} {event.has_numerated_seats ? ` / Entrada ${item.seat_name}` : ''}</span>}
                    {item.ticket_extra && <span> {item.ticket.name} / Extra</span>}
                  </span>
                  {!customized && !hasOpenPrice && <span>{formatCurrency(item.ticket.price)}</span>}
                </div>
              </div>
            </div>
        ))}
        <div className="bg-gray-100">
          {isFetching && <OrderTotalSkeleton />}
          {(orderTotals && !isFetching && !isBackoffice) && (
            <div>
              <div className="flex flex-col">
                <span className="p-[16px] flex flex-row justify-between">
                  Subtotal{' '}
                  <span
                    className={`${orderTotals.subtotal !== orderTotals.base_tickets_total ? 'line-through font-light' : ''
                    }`}
                  >
                    {formatCurrency(orderTotals.base_tickets_total)}
                  </span>{' '}
                  {(orderTotals.subtotal !== orderTotals.base_tickets_total)
                    && formatCurrency(orderTotals.subtotal)}
                </span>
                {!event.tickets_includes_service_charge && (
                  <span className="p-[16px] flex flex-row justify-between">
                    <div>Service charge{' '}</div>
                    <div>{formatCurrency(orderTotals.service_charge_fee)}</div>
                  </span>
                )}
                {(event.tickets_includes_service_charge && orderTotals.payment_method_discounts > 0) && (
                  <span className="p-[16px] flex flex-row justify-between">
                    <div>Descuento por medio de pago{' '}</div>
                    <div>{formatCurrency(orderTotals.payment_method_discounts)}</div>
                  </span>
                )}
                <span className="font-medium p-[16px] flex flex-row justify-between">
                  <div>Total a pagar</div>
                  <div>{formatCurrency(orderTotals.total_amount)}</div>
                </span>
              </div>
            </div>
          )}
          {(orderTotals && !isFetching && isBackoffice) && (
            <div className="flex flex-col">
              <span className="font-medium p-[16px] flex flex-row justify-between">
                <div>Total a pagar</div>
                <div>{formatCurrency(orderTotals.total_amount)}</div>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderSummary;
