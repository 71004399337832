const parse2decimals = (value: number) => parseFloat(value.toFixed(2));

const formatCurrency = (value: number, decimals: 'with-decimals' | 'no-decimals' = 'with-decimals') => {
  if (decimals === 'no-decimals') {
    return `$${new Intl.NumberFormat('es-AR', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)}`;
  }
  return `$${new Intl.NumberFormat('es-AR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)}`;
};

export { parse2decimals, formatCurrency };
