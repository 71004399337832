import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../store';
import { Text } from './Text';
import CountdownTimer from './CountdownTimer';
import Carrousel from './atoms/Carrousel/Carrousel';

const CreateOrderOutlet: React.FC = () => {
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const location = useLocation();
  const isBackoffice = location.pathname.includes('admin');
  const showPoll = event?.has_polls && !isBackoffice;
  const isSeatsPage = location.pathname.includes('orders/new/seats');
  const isTimeoutPage = location.pathname.includes('orders/new/timeout');
  const reservation = useSelector((state: RootState) => state.orders.reservation);

  const currentStep = () => {
    const url = location.pathname;
    if (url.includes('orders/new/tickets') || isSeatsPage) {
      return 1;
    }
    if (url.includes('orders/new/entry-data')) {
      return 2;
    }
    if (url.includes('orders/new/poll')) {
      return 3;
    }
    if (url.includes('orders/new/checkout')) {
      return showPoll ? 4 : 3;
    }
    return 0;
  };

  const getStepsNumber = () => (showPoll ? 4 : 3);

  return (
    <div className="pb-3">
      {isBackoffice && (
        <h1 className="text-2xl mb-3 ml-2">Carga de entradas</h1>
      )}
      {!isBackoffice && (
        <div className="mt-6 md:mt-12 mb-8">
          <Carrousel images={event?.banner_image ? [{ url: event.banner_image, eventId: event.id }] : []} />
        </div>
      )}
      <div className="flex flex-col justify-center items-center mb-8 text-lg leading-7">
          <h2 className="text-xl font-medium">{event?.name}</h2>
        {!isBackoffice && (
          <span className="text-sm">Organiza: {event?.business?.name}</span>
        )}
      </div>
      <div className="mb-4">
        {currentStep() !== 0 && <div className="flex flex-col p-[8px] pl-[16px] align-center justify-center items-center bg-white shadow rounded-lg mb-8">
          {!isTimeoutPage && <h3 className="font-normal text-base leading-7">Paso {currentStep()} de {getStepsNumber()}</h3>}
          {isSeatsPage && <Text className='mt-3' size='md' color='text-neutral-600'>Seleccione asientos en el mapa de la sala</Text>}
        </div>}
        {reservation && !isTimeoutPage && <CountdownTimer expirationDate={new Date(reservation.expires_at)} />}
        <Outlet />
      </div>
    </div>
  );
};

export default CreateOrderOutlet;
