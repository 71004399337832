import { PiCalendarBlank, PiTicket } from 'react-icons/pi';
import { CiLocationOn } from 'react-icons/ci';
import EventDTO from '../models/event';
import Button from './atoms/Button';
import { parseFriendlyDate } from '../utils/dates';
import LoadingText from './atoms/LoadingText';
import { useGetEventCapacityQuery } from '../store/apis/events';
import useGetUserRole from '../hooks/useGetUserRole';

interface EventCardProps {
  event: EventDTO;
  onSelect: (eventId: number) => void;
  colorToast?: string;
  textButton?: string;
  textToast?: string;
  adminEvents?: boolean;
}

const LandingEventCard: React.FC<EventCardProps> = ({
  event, onSelect, colorToast = 'bg-warning-light', textToast, textButton = 'Adquirir entradas', adminEvents,
}) => {
  let content;
  if (adminEvents) {
    const role = useGetUserRole();
    const { data: capacity, isFetching: isFetchingCapacity } = useGetEventCapacityQuery({ eventId: event.id, businessId: event.business_id });
    const ticketsSold = event.tickets.reduce((pv, cv) => pv + cv.amount_taken, 0);
    content = (role === 'ADMIN' || role === 'ROOT') && adminEvents && (
      <div className="flex items-center text-grey-600 text-md-medium break-words whitespace-normal text-center">
        <PiTicket className="mr-1 text-md-medium" />
        <LoadingText
          text={`${capacity?.used_tickets ?? '?'}/${ticketsSold} tickets vendidos`}
          isLoading={isFetchingCapacity}
        />
      </div>
    );
  }

  return (
    <div onClick={() => onSelect(event.id)} className="flex flex-col items-center w-full h-full p-2 shadow-sm rounded-2xl bg-white gap-3 hover:shadow-[0px_4px_6px_-1px_rgba(85,17,85,0.25),_0px_2px_4px_-1px_rgba(81,82,92,0.1)] relative before:absolute before:inset-0 before:rounded-2xl hover:cursor-pointer hover:before:border-r-4 hover:before:border-b-4 hover:before:border-primary-base before:pointer-events-none">
      <div className="w-full relative">
        {textToast && <div className={`${colorToast} p-1 rounded-lg w-fit absolute right-0 top-0 text-sm-medium ${colorToast === 'bg-warning-light' ? 'text-grey-700' : 'text-white'}`}>{textToast}</div>}
        <img
          src={
            event.banner_image
            || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMsG_C_QzoMZzPjUrOATtYljG9I3dBybHwjS7yAuDw1w&s'
          }
          alt={event.name}
          className="h-[140px] w-full  object-cover rounded-lg"
        />
      </div>

        <div className="flex flex-col items-center w-full gap-3 h-full">
          <div className='w-full'>
          <h2 className="text-xl-semibold text-grey-700 break-words whitespace-normal text-center" >{event.name}</h2>
        </div>
        {event.business && < p className="text-grey-600 text-sm-regular">Organiza: {event.business.name}</p>}
        <p className="flex items-center text-grey-600 text-md-medium">
          <PiCalendarBlank className="mr-1 text-md-medium" />
          {parseFriendlyDate(event.date, true)} - {event.time}
        </p>
        <p className="flex items-center text-grey-600 text-md-medium break-words whitespace-normal text-center">
          <CiLocationOn className="mr-1 text-md-medium " />
          {event.place}
        </p>
        {content}
        <div className='flex h-full items-end w-full'>
          <Button
            variant="secondary"
            disabled={!adminEvents && !event.active}
          >
            {textButton}
          </Button></div>
        </div>
      </div>
  );
};

export default LandingEventCard;
