import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import Button from './atoms/Button';
import config from '../config/config';
import { RootState, logout } from '../store';
import { auth } from '../config/firebase-config';
import useGetDefaultBusinessId from '../hooks/useGetFirstBusinessId';
import getSubdomain from '../utils/getSubdomain';
import MenuDropdown from './atoms/MenuDropdown';

const DesktopHeader: React.FC = () => {
  const navigate = useNavigate();
  const { token, user } = useSelector((state: RootState) => state.auth);
  const businessId = useGetDefaultBusinessId();
  const dispatch = useDispatch();

  const handleClick = (path: string) => {
    if (getSubdomain()) {
      window.location.href = `${config.baseDomain}${path}`;
    } else {
      navigate(path);
    }
  };

  const handleLogout = (path: string) => {
    handleClick(path);
    signOut(auth)
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClickSellAvantti = () => {
    window.open('https://organizadores.avantti.io/', '_blank');
  };

  const handleClickHelp = () => {
    window.open('https://wa.me/message/GV2TPBX7VGT5A1', '_blank');
  };

  return (
    <>
      <header className="fixed z-50 top-0 left-0 w-screen flex py-4 flex-col justify-center items-center self-center bg-white shadow-sm">
        <div className="flex px-8 items-center gap-8 self-stretch">
          <div className="flex items-center gap-10 flex-[1_0_0]">
            <span
              onClick={() => {
                window.location.href = config.baseDomain;
              }}
              className="flex h-18 cursor-pointer"
            >
              <img src="/avantti-header.svg" className="mr-3" />
            </span>

            <nav className="flex items-center gap-8">
              <a className="cursor-pointer flex py-0.5 flex-col align-center" onClick={() => handleClick('/')}>
                Home
              </a>
              {/* <a className="cursor-pointer flex py-0.5 flex-col align-center">
                <div className="flex justify-center items-center gap-2">
                  <div>Categorias</div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M5.20001 7.5L10.2 12.5L15.2 7.5" stroke="#3F3F46" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </a> */}
              <a className="cursor-pointer flex py-0.5 flex-col align-center" onClick={handleClickSellAvantti}>
                Quiero vender con avantti
              </a>
              <a className="cursor-pointer flex py-0.5 flex-col align-center" onClick={handleClickHelp}>
                Ayuda
              </a>
            </nav>
          </div>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg> */}
          {!token && (
            <div className='w-fit'>
              <Button variant="primary">
                <a onClick={() => handleClick('/login')}>Iniciar sesión</a>
              </Button></div>
          )}
          {token && (
            <div className='w-fit items-center bg-white rounded-lg shadow-xs border border-gray-100 flex'>
              <MenuDropdown
                label={user.username}
                data={user}
                options={[
                  ...(user?.user_businesses?.length > 1 || user?.type === 'ROOT' ? [{
                    label: 'Mis productoras',
                    onClick: () => handleClick('/admin'),
                  }] : []),
                  ...(businessId ? [{
                    label: 'Mis eventos',
                    onClick: () => handleClick(`/admin/${businessId}/events`),
                  }] : []),
                  {
                    label: 'Mis invitaciones',
                    onClick: () => handleClick('/admin/invitations'),
                  },
                  {
                    label: 'Cerrar sesión',
                    onClick: () => handleLogout('/'),
                    labelClassName: 'font-semibold text-white bg-gray-700 rounded-md px-2 py-1 mx-2 mt-1 text-center',
                  },
                ]}
                icon={<FaUserCircle className=" text-6xl h-[36px]" />}
                className="w-fit"
              />
            </div>
          )}
        </div>
      </header>
      <div className="h-[76px]" />
    </>
  );
};

export default DesktopHeader;
