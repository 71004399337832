import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { HiDownload } from 'react-icons/hi';
import config from '../config/config';
import OrderItem from '../types/OrderItem';
import FilterTable, { BaseData } from './Table/Table';
import MenuDropdown from './atoms/MenuDropdown';
import CustomModal from './atoms/CustomModal';
import {
  RootState,
  useDeleteOrderItemMutation,
  useSendOrderEmailMutation,
} from '../store';
import Button from './atoms/Button';
import EditOrderForm from './EditOrderForm';
import useGetParams from '../hooks/useGetParams';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

interface Props {
  orderItems: OrderItem[];
  buttonClick?: () => void;
  loading?: boolean;
}

// Update TableOrderItem to properly implement BaseData
interface TableOrderItem extends BaseData {
  id: number;
  displayId: string;
  name: string;
  personId: string;
  email: string;
}

const ApprovedOrdersTable: React.FC<Props> = ({ orderItems, buttonClick, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const { event } = useGetSelectedEvent();
  const { token } = useSelector((state: RootState) => state.auth);
  const { eventId, businessId } = useGetParams();
  const [sharePdfLoading, setSharePdfLoading] = useState(false);

  const [deleteOrderItem, resultsDelete] = useDeleteOrderItemMutation();
  const [sendEmail] = useSendOrderEmailMutation();

  const handleConfirmDeleteOrder = (item: OrderItem) => {
    toast.promise(
      deleteOrderItem({
        businessId,
        eventId: +eventId,
        orderId: item.id,
        itemId: item.item_id,
      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Entrada eliminada con éxito</b>,
        error: <b>Error al eliminar orden.</b>,
      },
    );
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteOrder = (item: OrderItem) => {
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <p className='text-xl font-medium'>¿Eliminar entrada?</p>
          <p className='text-sm text-gray-400'>Al eliminar la entrada quedará inhabilitada y no podrá ser utilizada.</p>
        </div>
        <div className='flex flex-row gap-2'>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => handleConfirmDeleteOrder(item)}
            className="text-center w-full mt-2"
          >
            Eliminar
          </Button>
        </div>
      </div>,
    );

    setShowModal(true);
  };

  const handleEditItem = (item: OrderItem) => {
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <p className='text-xl font-medium'>Editar datos</p>
          <p className='text-sm text-gray-400'>En los siguientes campos podrás editar los datos que necesites</p>
        </div>
        <EditOrderForm order={item} onSuccess={handleClose} onCancel={handleClose} />
      </div>,
    );

    setShowModal(true);
  };

  const resendEmail = (item: OrderItem) => {
    toast.promise(
      sendEmail({
        businessId,
        eventId: +eventId,
        customId: item.order_custom_id,
      }).unwrap(),
      {
        loading: 'Enviando correo...',
        success: <b>Correo enviado con éxito</b>,
        error: <b>Error al enviar correo.</b>,
      },
    );
  };

  const makeDownloadQrRequest = async (item: OrderItem) => {
    const response = await axios.get(
      `${config.baseURL}/v1/businesses/${businessId}/events/${eventId}/orders/${item.order_id}/items/${item.item_id}/qr-pdf`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  };

  const downloadQr = (item: OrderItem) => {
    toast.promise(
      makeDownloadQrRequest(item)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `QR_Ticket_${item.last_name}_${item.first_name}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          console.error(e);
          throw e;
        }),
      {
        loading: 'Obteniendo PDF...',
        success: <b>Descargando PDF</b>,
        error: <b>Error al obtener PDF</b>,
      },
    );
  };

  const shareTicket = async (item: OrderItem) => {
    try {
      setSharePdfLoading(true);
      const response = await makeDownloadQrRequest(item);
      const blob = response.data;
      const file = new File([blob], `QR_Ticket_${item.last_name}_${item.first_name}.pdf`, { type: 'application/pdf' });

      if (navigator.share && navigator.canShare({ files: [file] })) {
        await navigator.share({
          title: 'Compartir entrada',
          text: `Te compartimos tu entrada para ${event?.name}`,
          files: [file],
        });
      } else {
        toast.error('Tu navegador no permite compartir archivos');
      }
      setSharePdfLoading(false);
    } catch (error) {
      setSharePdfLoading(false);
      if (error instanceof Error && error.name !== 'AbortError') {
        toast.error('Tu navagador bloqueo compartir, intentá nuevamente');
        console.error('Share failed:', error);
      }
    }
  };

  const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  // More accurate share capability check
  const canShareFiles = () => {
    try {
      return navigator.canShare
             && navigator.canShare({ files: [new File([], 'test.pdf', { type: 'application/pdf' })] })
             && isMobile();
    } catch {
      return false;
    }
  };

  const dropDownOptions = [
    ...(canShareFiles() ? [{
      label: 'Compartir entrada',
      onClick: shareTicket,
    }] : []),
    {
      label: 'Descargar QR',
      onClick: downloadQr,
    },
    {
      label: 'Reenviar correo',
      onClick: resendEmail,
    },
    {
      label: 'Editar',
      onClick: handleEditItem,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteOrder,
    },
  ];

  const headCells = [
    {
      id: 'displayId',
      label: 'ID',
    },
    {
      id: 'name',
      label: 'Nombre',
    },
    {
      id: 'personId',
      label: 'DNI',
    },
  ];

  const tableRows: TableOrderItem[] = orderItems
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .map((item) => ({
      id: item.item_id,
      displayId: item.order_display_id,
      name: item.name,
      personId: item.person_id,
      email: item.email,
    }));

  const actions = (row: TableOrderItem) => (
    <div className='w-fit mx-auto'>
      <MenuDropdown<OrderItem>
        options={dropDownOptions}
        data={orderItems.find((item) => item.item_id === row.id) || orderItems[0]}
        loading={resultsDelete.isLoading || sharePdfLoading}
      />
    </div>
  );

  return (
    <div>
      <FilterTable
        rows={tableRows}
        headCells={headCells}
        actions={actions}
        pagination={{
          pageSize: 10,
          enabled: true,
        }}
        searchEnabled={true}
        customButtons={buttonClick ? [
          <Button variant="secondary" onClick={buttonClick} loading={loading}>
            <div className='flex flex-row gap-2 items-center'>
              <HiDownload className="w-5 h-5" />
              <span className='whitespace-nowrap hidden md:block'>Descargar listado</span>
            </div>
          </Button>,
        ] : []}
      />

      <CustomModal
        onRequestClose={handleClose}
        isOpen={showModal}
        hideCancelButton={true}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default ApprovedOrdersTable;
